.footer-copyright {
  background: #e1ebf5;
  color: #001d4b;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: 15px 0;
}

.footer-newsletter {
  background: #f0f5fa;
  color: #7a8fb1;
  padding: 30px 0;

  & .logo {
    margin: 0 40px 0 0;
  }
}

.footer-newsletter__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newsletter {
  & form {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .control-label {
    margin-right: 15px;
  }
}

.newsletter-email {
  position: relative;

  & .form-control {
    min-width: 240px;
    height: 40px;
    color: #7a8fb1;
    background: #fff;
    border-color: #7a8fb1;
  }

  & input::placeholder {
    color: #7a8fb1;
  }

  & input:focus::placeholder {
    color: transparent;
  }

  & i {
    position: absolute;
    right: 10px;
    top: 6px;
    color: #7a8fb1;
    font-size: 20px;
  }
}

.newsletter-loading {
  min-width: 240px;
  background: #7a8fb1;
  color: #fff;
  position: relative;
  padding: 8px 10px;

  & i {
    position: absolute;
    right: 10px;
    top: 6px;
    color: #fff;
    font-size: 20px;
  }
}

.newsletter-success {
  min-width: 240px;
  background: #00c10b;
  color: #fff;
  position: relative;
  padding: 8px 10px;

  & i {
    position: absolute;
    right: 10px;
    top: 6px;
    color: #fff;
    font-size: 20px;
  }
}

@media (max-width: 1000px) {
  .footer-newsletter__inner {
    flex-wrap: wrap;
  }

  .newsletter {
    padding: 30px 0 0 0;
  }

  .footer-newsletter .logo {
    margin: 0;
  }
}

@media (max-width: 576px) {
  .footer-newsletter__inner {
    & .newsletter {
      display: block;
      width: 100%;
    }
  }

  .footer-newsletter {
    padding: 30px 0;
  }

  .footer-copyright {
    padding: 15px 0;
  }

  .newsletter form {
    display: block;
  }
}