// search
.boat-search {
  position: relative;
  width: 100%;
  margin: 0;
  display: inline-block;

  & .control-label {
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 0 8px 0;
    width: 100%;

    & i {
      font-size: 20px;
    }
  }

  .help-block {
    display: none;
  }

  .form-group {
    margin: 0 0 20px 0;
  }

  & .form-control {
    height: 36px;
    border-color: #ececec !important;
  }

  & .form-group__btn {
    display: flex;

    & .btn {
      text-transform: uppercase;
    }
  }

  & .checkbox-item {
    & label {
      position: relative;
      display: inline-block;
      width: 100%;
      background: #ececec;
      font-weight: 400;
      padding: 7px 10px 8px 10px;
      font-size: 14px;
      transition: all 0.3s;
      margin: 0;

      &:hover {
        cursor: pointer;
        background: #17a7ea;
        color: #fff;
      }
    }

    & input[type=checkbox] {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    & input[type=checkbox]:checked + label {
      background: #17a7ea;
      color: #fff;
      padding-left: 28px;
    }

    & input[type=checkbox]:checked + label:before {
      content: "";
      display: block;
      width: 9px;
      height: 15px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 7px;
      left: 13px;
    }
  }

  & .input-group {
    & .form-control {
      height: 34px;
    }
  }
}

.btn-clear-filter {
  font-size: 15px;
  color: #cd2323;
  text-transform: uppercase;
  text-decoration: none !important;

  &:hover {
    color: #f92f2f;
  }
}

// category
.boat-search__category-group {
  & .form-group {
    margin-bottom: 10px;
  }
}

.boat-search__category {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & .checkbox-item {
    width: 50%;

    &:nth-child(even) {
      padding: 0 0 10px 5px;
    }

    &:nth-child(odd) {
      padding: 0 5px 10px 0;
    }
  }
}

// date
//.boat-search__date {
//  display: flex;
//
//  .form-group {
//    margin: 0;
//    width: 50%;
//  }
//
//  .form-group:nth-child(odd) .input-group {
//    border-right: none;
//  }
//
//  .input-group {
//    border: 1px solid #ececec;
//  }
//
//  & .input-group-addon {
//    background: #fff;
//    border-radius: 0;
//    border: none;
//    padding: 6px;
//    color: #909090;
//    font-size: 14px;
//  }
//
//  & .form-control {
//    border: none;
//    box-shadow: none;
//    font-size: 14px;
//  }
//}

.boat-search__with-captain {
  .form-group {
    margin: 0;
  }

  & label {
    font-weight: normal;
  }

  & .control-label {
    font-weight: bold;
    display: block;
    border-bottom: 1px solid #ccc;
    margin: 0 0 10px 0;
  }
}

// slider
.boat-search__slider-label {
  font-size: 13px;
  position: relative;
  top: 7px;
  padding: 0;
  text-align: center;
}

.boat-search__slider {
  & .slider-track {
    background: #909090;
    border-radius: 0;
    height: 2px !important;
    margin-top: -3px !important;
  }

  & .slider-selection {
    background: #17a7ea;
    height: 4px !important;
    margin-top: -1px;
  }

  & .slider-handle {
    background: #17a7ea;
    width: 15px;
    height: 15px;
    margin-left: -6px !important;
  }
}

.boat-search__slider-control {
  display: flex;

  & .form-group {
    margin-bottom: 5px;
    width: 50%;
  }

  & .input-group {
    border: 1px solid #ececec;
  }

  & .form-group:nth-child(odd) .input-group {
    border-right: none;
  }

  & .input-group-addon {
    background: #fff;
    border: none;
    border-radius: 0;
    padding: 4px;
    color: #909090;
    font-size: 12px;
  }

  & .form-control {
    border: none;
    box-shadow: none;
    color: #001d4b;
    font-size: 14px;
    text-shadow: 0 0 1px #001d4b;
    padding: 6px 4px 6px 0;
  }
}

// actions
.boat-result-action__sort {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
}

// extend
.extend-btn__action {
  background: #d7dee9;
  margin: 0 0 20px 0;
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
  transition: all 0.3s;
  color: #001d4b;

  &:hover {
    background: #c5ccd7;
  }

  & i {
    font-size: 20px;
  }

  & a {
    color: #001d4b;
    text-decoration: none !important;
  }
}

.share-result {
  margin-right: 15px;
}

.modal-share-result {
  display: flex;
  align-items: center;
}

// regions
.search-regions {
  display: flex;
  flex-wrap: wrap;
}

.search-region__label {
  margin: 0 10px 0 0;
}

.search-region__city {
  margin: 0 4px 5px 0;

  &:after {
    content: ", ";
  }
}

.search-region__city_cnt {
  display: none;
}

.search-region__city_more {
  display: none;
}

.search-region__more {
  flex-grow: 3;
  text-align: right;

  & a {
    display: inline-block;
    color: #2e2e2e;
    font-size: 14px;
    background: #ececec;
    padding: 3px 8px;
  }

  & a:hover, & a:focus {
    text-decoration: none;
    background: #dfdfdf;
  }
}

.search-regions_table {
  .search-region__city {
    max-width: 33%;
    flex: 0 0 33%;
    margin: 0 0 10px 0;

    &:after {
      content: "";
    }
  }

  .search-region__city_cnt {
    display: inline;
    font-size: 12px;
    color: #999999;
    margin-left: 5px;
  }

  .search-region__city_more {
    display: block;
  }

  .search-region__label {
    display: none;
  }

  .search-region__more {
    display: none;
  }
}

.boat-list__empty {
  background: #f5f5f5;
  padding: 15px;
  text-align: center;

  & h2 {
    position: relative;
    padding: 0 0 20px 0;
    margin: 0 0 30px 0;
  }

  & h2:after {
    content: '';
    position: absolute;
    left: 44%;
    bottom: 0;
    border-bottom: 1px solid #333;
    width: 12%;
    text-align: center;
  }

  & p {
    margin: 0 0 20px 0;
  }
}

@media (max-width: 1280px) {
  .btn-clear-filter {
    white-space: normal;

    & i {
      display: none;
    }
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1001px) {
  .boat-search__category .checkbox-item {
    width: 100%;
  }

  .boat-search__category .checkbox-item {
    padding: 0 0 10px 0 !important;
  }

  .boat-search {
    & .input-group-addon {
      display: none;
    }

    .form-control {
      padding: 6px 4px 6px 4px;
    }
  }
}

@media (max-width: 1000px) {
  .boat-search {
    display: none;
  }

  .filter_open {
    display: block;
  }

  .filter_hide {
    display: none;
  }
}