.ui-autocomplete {
  &.ui-widget.ui-widget-content {
    border: none !important;
    box-shadow: -1px 4px 11px 0px rgba(0, 0, 0, .1);
    z-index: 1011;
    padding: 8px;
  }

  & .ui-menu-item-wrapper {
    padding: 5px 5px;
    text-decoration: none !important;

    & img {
      margin-right: 5px;
    }
  }

  &.ui-menu .ui-menu-item {
    margin-bottom: 0;
  }

  .ui-menu-item-wrapper {
    &.ui-state-active {
      background: #eff8f8 !important;
      border: 1px solid #fff !important;
    }
  }

  .ui-menu-item {
    & strong {
      margin-right: 1px;
    }
  }
}