.modal {
  bottom: auto;
}

.modal_center {
  text-align: center;
  padding: 0!important;
}

.modal_center:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px; /* Adjusts for spacing */
}

.modal_center {
  & .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}

.modal-content {
  border-radius: 0;
}

.modal-backdrop {
  background-color: #00adff;
}

.modal-backdrop.in {
  opacity: 0.9;
}