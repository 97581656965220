.pagination {
  & > li > a {
    border: none;
    color: #708296;
  }

  & > .active > a {
    background: none;
    font-size: 18px;
    font-weight: bold;
    color: #121c26;
    position: relative;
    top: -2px;
  }

  & .next a, & .prev a, & .next span, & .prev span {
    position: relative;
    color: #fff;
    border: none;
    top: 3px;

    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 10px;
      top: 9px;
    }

    &:hover {
      color: #eee;
    }
  }

  & .prev a:before, & .prev span:before {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAKBAMAAAB76QKzAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAALVBMVEX///9wgpZwgpZwgpZwgpZwgpZwgpZwgpZwgpZwgpZwgpZwgpZwgpZwgpb////PKoZqAAAADXRSTlMAHK0EcfM40BGX/lzpxUPNVAAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAnSURBVAjXY2BgYBACYpNYBga22rsMHLPu3mXYcxdGgLlgCYgSoGIAeVEPZQCQ8ywAAAAASUVORK5CYII=');
  }

  & .next a:before, & .next span:before {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAKBAMAAAB76QKzAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAALVBMVEX///9wgpZwgpZwgpZwgpZwgpZwgpZwgpZwgpZwgpZwgpZwgpZwgpZwgpb////PKoZqAAAADXRSTlMArRzzcQTQOP6XEelcU+hY3QAAAAFiS0dEAIgFHUgAAAAJb0ZGcwAAAzQAAAPXALgWBGkAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAJdnBBZwAAB4AAAASwAC0JkTsAAAAlSURBVAjXYxBiAILLrkDi7rUCIHG3cwGQuLsHRoC4IAmwEpBiAOM2EwcNJottAAAAAElFTkSuQmCC');
  }

  & li.first > span, & li.last > span {
    border: 0;
  }
}

.pagination-link__more {
  display: none;
}

@media (max-width: 575px) {
  .pagination-link__more {
    color: #7e7e7e;
    background: #f5f5f5;
    font-size: 20px;
    display: block;
    text-align: center;
    padding: 10px 20px;
    border: none;
    outline: none !important;
    width: 100%;
  }

  .pagination {
    width: 100%;
    margin: 0;

    & li {
      display: none;
    }

    & .next {
      display: block;
      width: 100%;
    }

    & .next a {
      color: #fff;
      padding: 0;
      width: 100%;

      &:before {
        display: none;
      }

      &:hover, &:focus {
        background: none !important;

        &.pagination-link__more {
          background: #e6e6e6;
        }
      }
    }
  }
}