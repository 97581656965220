.yachts-model {
  position: relative;
  height: 290px;
  max-width: 350px;
  width: 100%;
  display: block;
  margin: 20px auto 0 auto;

  & > img {
    height: 100%;
    object-fit: cover;
  }
}

.yachts-model__info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(23, 167, 234, 0.7);
  color: #fff;
}

.yachts-model__name {
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 15px 0;

  & a {
    color: #fff;
    text-decoration: underline;

    &:hover, &:focus {
      color: #fff;
      text-decoration: none;
    }
  }

  & i {
    &:before {
      font-size: 26px;
      line-height: 100%;
      position: relative;
      top: 3px;
      margin: 0;
    }
  }
}

.yachts-model__2yachts {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  white-space: nowrap;

  & img {
    margin-left: 8px;
    width: 104px;
    height: 13px;
  }
}

