.page-error__header {
  background-image: linear-gradient(to top, #169ce4 0%, rgba(0, 29, 75, 0.75) 100%);
  padding: 80px 0 45px 0;

  & h1 {
    text-align: center;
    font-size: 30px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.page-error__body {
  padding: 20px 0;
}

.page-error__boats {
  background: #f5f5f5;
  padding: 20px 0;
}

//.page-error__boats .container {
//  display: flex;
//}