.boat {
  position: relative;
}

.boat-header__photos {
  display: flex;
}

.boat-header__main {
  position: relative;
  width: 66.35%;
  overflow: hidden;
  flex-grow: 0;

  & .boat-photo {
    width: 100%;
    display: block;
  }

  & img {
    width: 100%;
    max-height: 442.335px;
    object-fit: cover;
  }
}

.model-header__additional-images {
  width: 33.65%;
  overflow: hidden;
  flex-grow: 0;
  position: relative;

  & .boat-photo {
    width: 50%;
    float: left;
    box-sizing: border-box;
    display: block;
    position: relative;
    vertical-align: top;
    overflow: hidden;
    background: #eee;
    //-webkit-backface-visibility: hidden;
    //-webkit-transform: translateZ(0) scale(1, 1);
    padding-top: 49.4%;
    height: 0;
    cursor: pointer;

    &:before {
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: "";
      background-image: url(/img/icons/icon-magnify-hover.svg);
      background-size: 50px auto;
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
    }

    & img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      height: 100%;
      width: 100%;
      transform: rotate(0);
      transition: all .2s ease-in-out;
    }

    &:hover, &:focus {
      text-decoration: none !important;

      &:before {
        opacity: 1;
      }

      & img {
        transform: translateZ(0) scale(1.1);
      }
    }
  }
}

.boat-header__title {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 20px;
  color: #fff !important;
  font-weight: bold;
  margin: 0;
  font-size: 24px;
  text-transform: uppercase;
  padding-right: 60px;
  background: rgba(23, 167, 234, .75);
}

.boat-header__shortlist {
  position: absolute;
  top: 14px;
  right: 13px;

  & a {
    text-indent: -9999px;
    overflow: hidden;
    width: 32px;
    height: 27px;
    background: url(/img/icons/icon-shortlist.svg) no-repeat;
    background-size: 32px;
    display: block;
  }

  & a:hover, & a:focus {
    cursor: pointer;
    background: url(/img/icons/icon-shortlist-hover.svg) no-repeat;
    background-size: 32px;
  }

  & a.active {
    background: url(/img/icons/icon-shortlist-active.svg) no-repeat;
    background-size: 32px;
  }
}

.boat-tabs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  list-style: none;
  padding: 0;
  margin: 10px -5px 0 -5px;
}

.boat-tab {
  margin: 0 5px;
  display: block;
  width: 100%;

  & a, .boat-tab__inner {
    display: flex;
    align-items: center;
    background: #f5f5f5;
    text-transform: uppercase;
    color: #909090;
    padding: 10px;
    text-align: center;
    white-space: nowrap;

    & span {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-left: 6px;
    }
  }

  & a:hover, & a:focus {
    background: #fff;
    text-decoration: none;
    color: #2e2e2e;
  }

  & a.active {
    background: #fff;
    text-decoration: none;
    color: #2e2e2e;
  }
}

.boat-tab_inactive {
  cursor: not-allowed;
}

// boat main
.boat-panel__title {
  text-decoration: none !important;
  display: block;
  position: relative;
  margin: 0 0 10px 0;

  & h2 {
    color: #2e2e2e;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.boat-panel {
  padding: 0 0 10px 0;
}

.boat-col {
  padding: 20px 0 0 0;
  display: flex;
}

.boat-col__info {
  padding-right: 20px;
  width: 100%;
}

.boat-col__dealer {
  flex: 0 0 350px;
  max-width: 350px;
}

.boat-panel {
  margin: 0 0 20px 0;
  position: relative;

  &:after {
    content: " ";
    display: block;
    border-bottom: 1px solid #ececec;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  & h2 {
    margin: 0;
  }
}

.boat-info {
  padding: 0 0 10px 0;
}

.boat-info__short {
  color: #26aaeb;
}

.boat-info__title {
  font-size: 26px;
  margin: 10px 0;
  font-weight: 700;
}

.boat-user-actions {
  list-style: none;
  padding: 0;

  & li {
    display: inline-block;
  }
}

.boat-info__opportunity {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #7a8fb1;
  font-size: 14px;

  & i {
    font-size: 20px;
  }

  & li {
    display: inline-block;
    margin-right: 5px;

    & span {
      font-weight: 700;
      margin-right: 3px;
    }
  }
}

// specification
.boat__specification {
  padding: 30px 0;
  border-bottom: 1px solid #ececec;
}

.boat-specification {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.boat-specification__row {
  padding: 5px 10px;
  display: inline-flex;
  width: 100%;

  &:nth-of-type(2n+1) {
    background: #d7dee9;
  }
}

.boat-specification__label {
  max-width: 40%;
  flex: 0 0 40%;
  word-break: break-all;
  /* Non standard for WebKit */
  word-break: break-word;
}

.boat-specification__value {
  font-weight: bold;
}

//description
.boat__description {
  padding: 30px 0;
  border-bottom: 1px solid #ececec;
}

// equipment
.boat__equipment {
  padding: 30px 0;
  border-bottom: 1px solid #ececec;
}

// Equipment
.equipment {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.equipment-group {
  width: 33%;
}

.equipment-group__title {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.equipment__list {
  list-style: none;
  padding: 0;
}

.equipment-list__item {
  margin: 0 0 10px 0;
  color: #909090;
}

.equipment-list__item_active {
  color: #2e2e2e;

  & i {
    color: #37ab1e;
  }
}

// dealer
.boat-dealer {
  background-color: #001d4b;
  color: #fff;
  min-width: 345px;

  & h3 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 21px;
  }
}

.boat-dealer__date {
  background: #f0f5fa;
  color: #7a8fb1;
  padding: 3px 5px;
  font-size: 14px;
}

.boat-dealer__price {
  font-weight: bold;
  font-size: 24px;
  color: #17a7ea;

  & small {
    color: #909090;
    font-size: 16px;
  }
}

.boat-dealer__price-value {
  white-space: nowrap;
}

.boat-dealer__inner {
  padding: 20px;
  text-align: center;
}

.boat-dealer__broker {
  & img {
    min-height: 100px;
  }
}

.boat-dealer__broker-name {
  padding: 10px 0;
  font-size: 18px;
}

.boat-dealer-charter-total {
  position: relative;
  top: -5px;
  display: flex;
  justify-content: space-between;
}

.boat-dealer-charter-total__label {
  font-weight: bold;
}

.boat-dealer-charter-total__error {
  font-size: 12px;
  color: #DC3545;
}

.btn-request {
  font-size: 20px;
  text-transform: uppercase;
}

// gallery
.boat__gallery {
  padding: 30px 0;
  background: #ececec;
}

.boat-gallery__inner {
  display: flex;
  overflow: hidden;

  & h2 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .owl-nav {
    position: absolute;
    left: -25%;
    bottom: 0;
  }

  .owl-dots {
    display: none;
  }
}

// similar
.boat__similar {
  padding: 30px 0;
}

.boat-similar__inner {
  display: flex;
}

.boat-similar__inner h2 {
  max-width: 25%;
  flex: 0 0 25%;
}

.boat-similar-list {
  width: 100%;
}

// source
.boat-source {
  margin: 0 0 20px 0;
}

.boat-source__host {
  list-style: none;
  padding: 0;
}

.boat__updated {
  color: #aaa;
  font-size: 12px;
  text-align: left;
}

// mobile
.boat-header_mobile {
  position: relative;

  & .owl-dots {
    display: none;
  }
}

.mobile-photo__counter {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 15px;
  background: rgba(23, 167, 234, .75);
  color: #fff;
  z-index: 10;
}

.boat-panel:after {
  display: none;
}

.boat-info:after {
  display: block;
}

.boat-dealer__preview {
  display: block;
  text-decoration: none !important;
}

.boat-dealer__action {
  display: none;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.boat-dealer__contact-header {
  display: none;
}

.boat-dealer__book-amount {
  text-align: center;
  font-size: 24px;
}

.datepicker--cell_busy {
  position: relative;
  color: #b9b0b0;

  & > span {
    display: inline-block;
    width: 100%;
  }

  &:after, &:before {
    position: absolute;
    left: 21px;
    content: ' ';
    height: 25px;
    width: 1px;
    background-color: #969696;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }
}

.boat-not-found {
  padding: 70px 0 30px 0;
  color: #fff;
  text-align: center;
  background: linear-gradient(to top, #169ce4 0%, rgba(0, 29, 75, 0.75) 100%);
}

@media (min-width: 1001px) {
  .boat-panel .collapse, .boat-panel .collapsing {
    display: block;
    height: auto !important;
    visibility: visible;
  }

  .boat-panel__title {
    cursor: default !important;
  }
}

@media (max-width: 1000px) {
  .boat-model-item {
    flex: 0 0 33%;
    max-width: 33%;
  }

  .boat-header {
    display: none;
  }

  .boat-header_mobile {
    display: block !important;
  }

  .boat-info {
    padding: 0 0 20px 0;
  }

  .boat__similar {
    padding: 0;
  }

  .boat-similar__inner h2 {
    max-width: 100%;
    margin: 0 0 10px 0;
    font-size: 20px;
    text-transform: uppercase;
  }

  // toggle block
  .boat-panel__title {
    &:after {
      font-family: "icon";
      content: '\e806';
      font-size: 28px;
      line-height: 0;
      color: #2e2e2e;
      position: absolute;
      top: 50%;
      right: 0;
    }
  }

  .boat-panel:after {
    display: block;
    border-bottom: 2px solid #ececec;
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  .boat-panel__container {
    padding: 10px 0 20px 0;
  }

  .boat-specification {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }

  .equipment-group {
    width: 50%
  }

  .boat-header__main {
    width: 100% !important;

    & img {
      max-height: 100% !important;
    }
  }

  .model-header__additional-images {
    display: none;
  }

  .boat-col {
    padding: 10px 0 0 0;
    flex-wrap: wrap;
  }

  .boat-info__title {
    margin-top: 10px;
  }

  .boat-col__info {
    order: 2;
    padding-right: 0;
  }

  .boat-col__dealer {
    order: 2;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }

  .boat-tab_inactive {
    display: none;
  }

  .boat-source {
    display: none;
  }

  .boat-col__dealer {
    margin: 0;
  }

  .boat__gallery {
    display: none;
  }

  .boat-similar__inner {
    display: block;

    & h2 {
      max-width: 100%;
      margin: 0 0 10px 0;
    }
  }

  .boat-dealer__preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #17a7ea;
    color: #fff;
    z-index: 12;
    padding: 15px 15px 15px 15px;
    text-align: left;
    line-height: 100%;

    &:hover, &:focus {
      color: #fff;
      cursor: pointer;
    }

    & .boat-dealer__price {
      color: #fff;
      flex: 0 0 40%;
      max-width: 40%;
      font-size: 22px;
    }

    & small {
      color: #fff;
    }
  }

  .boat-dealer {
    padding: 0;
  }

  .boat-dealer__action {
    display: block;
    font-size: 19px;
    position: relative;
    left: 7px;

    & i {
      font-size: 26px;
      line-height: 0;
      position: relative;
      top: 3px;

      &:before {
        margin: 0;
      }
    }
  }

  .boat__updated {
    display: none;
  }

  .boat-dealer__contact {
    display: block;
    z-index: -1;
    opacity: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: #fff;
  }

  .boat-dealer__contact_open {
    transition: opacity 0.3s;
    opacity: 1;
    z-index: 1100;
    overflow-y: auto;
  }

  .boat-dealer__contact-header {
    padding: 15px;
    background: #17a7ea;
    color: #fff;
    font-weight: bold;
    font-size: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .boat-dealer__contact-close {
    color: #fff !important;
  }

  .boat-dealer__form-title {
    display: none;
  }

  .boat-dealer__contact-inner {
    padding: 15px 15px 70px 15px;
  }

  .boat-dealer__inner {
    text-align: left;
  }

  .boat-dealer__form {
    & input, & textarea {
      color: #2e2e2e;
      background-color: #ececec;
    }

    & .btn-block {
      padding: 12px 10px;
      text-transform: uppercase;
    }
  }

  .boat-dealer__broker-title {
    display: none;
  }

  .boat-dealer__broker {
    display: flex;
    justify-content: space-between;
    padding: 0 0 30px 0;
  }

  .boat-dealer__photo {
    flex: 0 0 120px;
    max-width: 120px;

    & img {
      height: 120px;
      width: 120px;
      object-fit: cover;
      min-height: auto;
      border-radius: 50%;
    }
  }

  .boat-dealer__info {
    width: 100%;
    padding: 0 0 0 20px;
  }

  .boat-dealer__broker-name {
    font-size: 22px;
    font-weight: bold;

    & a {
      color: #2e2e2e;
    }
  }

  .boat-dealer__country {
    & .flag-icon {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .boat__breadcrumbs {
    display: none;
  }

  .boat-model-item {
    flex: 0 0 50%;
    max-width: 335px;
  }

  .boat-model-item__photo img {
    width: 100%;
    max-width: 100%;
  }

  .boat-header__main {
    margin: 0 -15px;
    width: calc(100% - 30px * -1) !important;
  }

  //.boat-search {
  //  .noUi-target {
  //    margin: 15px 20px;
  //  }
  //}

  .boat-dealer__date {
    display: none;
  }

  .boat-dealer {
    background: none;
  }
}

@media (max-width: 568px) {
  .boat-specification {
    margin: 0 -15px;
  }

  .boat__breadcrumbs {
    display: none;
  }

  .boat-list {
    justify-content: center;
  }

  .boat-model-item {
    flex: 0 0 100%;
    max-width: 335px;
  }

  //.boat-specification__row {
  //   display: block;
  //}

  .boat-specification__label {
    max-width: 100%;
  }

  .equipment-group {
    width: 100%
  }

  .boat-gallery__inner {
    display: block;

    & h2 {
      max-width: 100%;
      margin: 0 0 10px 0;
    }

    & .owl-nav {
      display: none;
    }
  }

  .boat-col__dealer {
    margin-bottom: 0px;
  }

  .boat__equipment {
    border-bottom: none;
    padding-bottom: 10px;
  }

  .boat-similar-list {
    margin: 0 -15px;
    width: auto;
  }
}