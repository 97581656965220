@import "base/_variables";
@import "base/_icons";
@import "base/_icons-codes";
@import "base/_loading";
@import "base/_buttons";
@import "base/_table";
@import "base/_tabs";
@import "base/_forms";
@import "base/_checkbox";
@import "base/_autocomplete";
@import "base/_pagination";
@import "base/_alert";
@import "base/_modal";
//@import "base/_animate";

@import "widgets/_common";
@import "widgets/_breadcrumbs";
@import "widgets/_currency-select";
@import "widgets/_multi-lang";
@import "widgets/_sort-select";
@import "widgets/_go-to";
@import "widgets/_shares";
@import "widgets/_random-boats";
@import "widgets/_carousel";
@import "widgets/_yachts-model";

@import "structure/_common";
@import "structure/_header";
@import "structure/_footer";

@import "pages/_site";
@import "pages/_error";
@import "pages/_sale";
@import "pages/_map";
@import "pages/_boat";
@import "pages/_boat-search";
@import "pages/_boat-list";
@import "pages/_contacts";
@import "pages/_page";
@import "pages/_classes";
@import "pages/_model";
