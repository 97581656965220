.alert {
  z-index: 10;
  position: relative;
  border: 1px solid;
  margin: 20px 0;
  padding: 15px 10px 15px 50px;
  background-repeat: no-repeat;
  background-position: 10px center;
}

.alert-info {
  color: #00529B;
  background-color: #BDE5F8;
  background-image: url(/img/alert/info.png);
}

.alert-success {
  color: #4F8A10;
  background-color: #DFF2BF;
  background-image: url(/img/alert/success.png);
}

.alert-warning {
  color: #9F6000;
  background-color: #FEEFB3;
  background-image: url(/img/alert/warning.png);
}

.alert-danger {
  color: #D8000C;
  background-color: #FFD1CE;
  background-image: url(/img/alert/error.png);
}

.alert-validation {
  color: #fb3401;
  background-color: #FFD1CE;
  background-image: url(/img/alert/error.png);
}

.error-summary {
  ul {
    list-style: none;
    padding: 0;
  }
}