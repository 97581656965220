.sale-header {
  padding: 25px 0 25px 0;
  background: #f0f5fa;
}

.sale-header__title {
  color: #2e2e2e;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-size: 36px;
  margin: 0 0 10px 0;
}

.sale-header__note {
  text-align: center;
  color: #2e2e2e;
  font-size: 16px;
  margin: 0 0 30px 0;
}

.map-preview {
  cursor: pointer;
  background: url(/img/preview-map.png) 0% 100% no-repeat;
  display: block;
  margin: 0 0 20px 0;
  width: 100%;
  overflow: hidden;
  text-align: center;
  transition: all .3s;

  & span {
    color: #fff;
    padding: 22px 12px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
  }

  &:hover {
    opacity: .75;
  }
}

.map-preview_closed {
  display: none;
}
.sale-body {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.sale-body__search {
  flex: 0 0 330px;
  width: 330px;
  background: #eff3f7;
  padding: 25px 20px;
}

.sale-body__result {
  width: 100%;
}

.sale-body__result-inner {
  padding: 25px 0 0 20px;

  & .pagination {
    margin-bottom: 0;
  }
}

.sale-header_bg {
  background-position: center center;
  background-repeat: repeat-x;
  background-size: cover;
  height: 240px;
  color: #fff;

  & .breadcrumb {
    & li, & a {
      color: #fff;
    }
  }

  & .sale-header__title {
    color: #2e2e2e;
    font-weight: 800;
    font-size: 52px;
    margin: 40px 0 10px 0;
  }

  & .sale-header__note {
    font-size: 20px;
    color: #fff;
  }
}

.boat-search__mobile {
  display: none;
}

@media (max-width: 1280px) {
  .sale-body__search {
    padding: 10px;
    flex: 0 0 280px;
    width: 280px;
  }
}

@media (max-width: 1000px) {
  .sale-body {
    display: block;
  }

  .sale-body__search {
    width: auto;
    margin: 0 -15px;
  }

  .sale-body__result-inner {
    padding: 20px 0;
  }

  .boat-search__mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }

  .btn-filter-mobile {
    text-transform: uppercase;
    color: #2e2e2e;
    text-decoration: none !important;

    & i {
      font-size: 20px;
    }
  }

  .filter_open {
    display: block;
  }

  .filter_hide {
    display: none;
  }
}

@media (max-width: 600px) {
  .sale-header__title {
    font-size: 26px;
  }

  .sale-header_bg {
    & .sale-header__title {
      font-size: 40px;
      margin: 15px 0 10px 0;
    }
  }

  .sale-header__brief-expand {
    max-height: 47px;
    overflow: hidden;
    order: 0;
  }

  .sale-header__brief-expand_open {
    max-height: 100%;
  }

  .sale-header__brief-more{
    display: inline !important;
    float: right;

    &:hover {
      cursor: pointer;
    }
  }
}
