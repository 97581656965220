.language-icon {
  display: inline-block;
  width: 22px;
  height: 20px;
  background: url(/img/flags.png) no-repeat;
  background-size: cover;
}

.language-icon_ru {
  background-position: -25px 0;
}

.language-icon_de {
  background-position: -51px 0;
}

.language-icon_fr {
  background-position: -77px 0;
}

.language-icon_it {
  background-position: -102px 0;
}

.language-icon_es {
  background-position: -128px 0;
}