// carousel
.owl-nav {
  padding: 10px 0 0 0;

  & button {
    width: 30px;
    height: 30px;
    outline: none;
    background: #f0f5fa !important;

    &:hover {
      background: #e5eaef !important;
    }

    & i:before {
      font-size: 24px;
      line-height: 140%;
      margin: 0;
      color: #001d4b;
    }
  }

  & button + button {
    margin-left: 5px;
  }

  & button.disabled {
    background: #d7dee9 !important;

    & i:before {
      color: #7a8fb1 !important;
    }
  }
}