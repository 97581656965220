@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url('../fonts/montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/montserrat/Montserrat-Regular.woff') format('woff'),
  url('../fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Italic.eot');
  src: local('Montserrat Italic'), local('Montserrat-Italic'),
  url('../fonts/montserrat/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/montserrat/Montserrat-Italic.woff') format('woff'),
  url('../fonts/montserrat/Montserrat-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Bold.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
  url('../fonts/montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/montserrat/Montserrat-Bold.woff') format('woff'),
  url('../fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Medium.eot');
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
  url('../fonts/montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/montserrat/Montserrat-Medium.woff') format('woff'),
  url('../fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// only ie10 ie11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  @import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700,800&amp;subset=cyrillic-ext&display=swap');
}

html,
body {
  height: 100%;
  min-width: 320px;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  font-family: 'Manrope', sans-serif;
  color: #001d4b;
}

img {
  max-width: 100%;
}

.show-overlay {
  overflow: hidden !important;
  position: fixed;
  width: 100%;
}

a {
  color: #169ce4;

  &:hover, &:focus {
    color: #1278ae;
  }
}

.wrap {
  min-height: calc(100% - 185px);
}

.b-lazy {
  -webkit-transition: opacity 500ms ease-in-out;
  -moz-transition: opacity 500ms ease-in-out;
  -o-transition: opacity 500ms ease-in-out;
  transition: opacity 500ms ease-in-out;
  max-width: 100%;
  opacity: 0.5;
  position: relative;
}

.image-wrapper {
  background-image: url(/img/loading.gif) !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.ratio_square {
  padding-bottom: 0%;
  height: 0;
  width: 100%;
  display: block;
}

.ratio_16-9 {
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
  display: block;
}

.ratio_model {
  padding-bottom: 50.25%;
  height: 0;
  width: 100%;
  display: block;
}

.b-lazy.b-loaded {
  opacity: 1;
}

.d-flex {
  display: flex;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.mr-5 {
  margin-right: 5px;
}

.ui-menu-item {
  & .flag-icon {
    margin: 0 10px 0 0;
    position: relative;
    top: -1px;
  }
}

.loading-img {
  width: 100px;
  height: 100px;
  background-image: url(/img/loading.gif) !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.text-expand, .text-less {
  cursor: pointer;
}

// backend
.backend-manage {
  position: fixed;
  right: -39px;
  top: 50%;
  z-index: 11;
  transform: rotate(-90deg);

  & a {
    display: block;
    background: #337ab7;
    padding:3px 10px;
    color: #fff;
    text-decoration: none !important;
  }

  & a:hover, & a:focus {
    background: #3ea6ee;
    text-decoration: none !important;
  }
}

.grecaptcha-badge{
  display: none;
}

.page-title {
  color: #2e2e2e;
  text-transform: uppercase;
}

@media (min-width: 1400px) {
  .container {
    width: 1370px;
  }
}

@media (max-width: 1400px) {
  .container {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .page-image {
    background-size: cover;
  }

  .cy {
    display: none;
  }
}

@media (max-width: 767px) {
  .page-error__boats .container {
    display: block;
  }

  main {
    padding: 0;
  }

  .hidden-md {
    display: none;
  }
}

@media (max-width: 575px) {
  .h3, h3 {
    font-size: 18px;
  }
}