input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.control-label {
  margin: 0 0 2px 0;
  font-weight: 500;
}

.form-control {
  border-radius: 0;
  border-color: #ececec;
  font-size: 16px;
  padding: 5px 10px 6px 10px;
  color: #2b446b;

  &:focus {
    box-shadow: none !important;
    border-color: inherit;
  }
}

.form-control::-webkit-input-placeholder {
  color: #7a8fb1;
}

.form-control::-moz-placeholder {
  color: #7a8fb1;
}

.form-control:-ms-input-placeholder {
  color: #7a8fb1;
}

.form-control::-ms-input-placeholder {
  color: #7a8fb1;
}

.form-control::placeholder {
  color: #7a8fb1;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #d8d7d7;
}

// select
select.form-control {
  background: url('/img/form_select-arrow-icon.gif') right -6px center no-repeat #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: 9px 6px;
  background-origin: content-box;
}

.select2-container--bootstrap .select2-results > .select2-results__options {
  max-height: 240px;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  color: #2b446b;
}

// slider
.slider.slider-horizontal {
  width: 100%;
}

.slider-selection {
  background-image: linear-gradient(to bottom, #90dbff, #90dbff 100%);
}

.noUi-handle{
  background: #001d4b;
}

.noUi-connect {
  background: #001d4b;
}

.noUi-target {
  background: #7a8fb1;
}

// autocomplete
.ui-autocomplete {
  z-index: 10000 !important;
  background: #fafeff !important;
  margin: 0 0 0 -32px !important;
}

.ui-autocomplete .ui-menu-item-wrapper {
  font-size: 14px;
}

// validations
.form-control_error {
  border-color: #ff1a00 !important;
}

.has-error .help-block {
  color: #fd0500 !important;
  font-size: 12px;
  margin: 2px 0 0 0;
  text-align: left;
}

.has-error .form-control {
  // border-color: #ececec !important;
  border-color: #fd0500 !important;
}

.hint-block {
  font-size: 13px;
}

.has-error .checkbox, .has-error .checkbox-inline, .has-error .control-label, .has-error .help-block, .has-error .radio, .has-error .radio-inline, .has-error.checkbox label, .has-error.checkbox-inline label, .has-error.radio label, .has-error.radio-inline label {
  color: #fd0500;
}

.required .control-label:after {
  content: " *";
  color: #ff0000;
}

.rating-stars .star i:before {
  margin: 0;
}

.rating-container .filled-stars {
  color: #09c4ff;
}

.input-group-addon {
  border-color: #eee;
}

.has-success .checkbox, .has-success .checkbox-inline, .has-success .control-label, .has-success .help-block, .has-success .radio, .has-success .radio-inline, .has-success.checkbox label, .has-success.checkbox-inline label, .has-success.radio label, .has-success.radio-inline label {
  color: #333;
}