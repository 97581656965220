.page-header {
  background: none;
  border-bottom: none;
  margin: 10px 0 0 0;
}

.page-body {
  padding: 20px 0;
}

.breadcrumb {
  z-index: 1;
  margin: 10px 0 10px 0;
  position: relative;
}

.blog-title {
  padding: 25px 0 25px 0;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    margin-top: -25px;
    padding: 40px 0 40px 0;
    background: #f0f5fa;
    width: 100%;
    z-index: -1;
  }
}

.page-title {
  margin: 0;
}

.breadcrumb a:after {
  content: ">";
  position: relative;
  right: -10px;
  font-size: 15px;
  top: 1px;
}

.topics-list {
  padding-left: 15px;
  padding-right: 15px;
}