.classes-header {
  padding: 25px 0 25px 0;
  background: #f0f5fa;
  margin: 0;
}

.classes-header__title {
  text-align: center;
  text-transform: uppercase;
  color: #2e2e2e;
  font-size: 36px;
  margin: 0 0 20px 0;
}

.classes-body {
  padding: 40px 0;
}

.classes-list {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0 0 40px 0;

  & li {
    margin: 0 15px;
  }

  & a {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    color: #7a8fb1;
    transition: color 0.3s;
    padding-bottom: 3px;
    text-decoration: none !important;

    &.active, &:hover, &:focus {
      border-bottom: 3px solid #16d8e4;
      color: #001d4b;
    }
  }
}

.classes-info {
  display: flex;
}

.classes-info__photo {
  position: relative;
  max-width: 65%;
  flex: 0 0 65%;

  & img {
    width: 100%;
  }
}

.classes-info__photo-name {
  position: absolute;
  right: 0;
  top: 15%;
  display: inline-block;
  color: #fff;
  z-index: 1;
  background: #001d4b;
  white-space: nowrap;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  padding: 5px 15px;
}

.classes-info__introtext {
  width: 100%;
}

.classes-info__introtext-inner {
  background: #edf1f6;
  color: #001d4b;
  padding: 40px;
}

.classes-info__introtext-text {
  margin: 0 0 40px 0;

  & a {
    display: inline-block;
    padding: 5px 10px;
    background: #d7dee9;
    color: #001d4b;

    &:hover, &:focus {
      text-decoration: none;
      background: #c9d0db;
    }
  }
}

.classes-info_even {
  & .classes-info__photo {
    order: 1;
  }

  & .classes-info__introtext {
    order: 0;
  }

  & .classes-info__photo-name {
    left: 0;
    right: auto;
  }
}

.class-description {
  column-count: 2;
}

.class-model-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 30px 0;
}

.class-model-list__item {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 10px 10px 0;
}

.class-model {
  display: flex;
  position: relative;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
  }
}

.class-model-list__item:nth-child(2) {
  padding-right: 0;
}

.class-model-list__item:nth-child(n+3) {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.class-model-list__item:nth-child(5) {
  padding-right: 0;
}

.class-model__title {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
}

.class-model__title-name {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.class-model__title-price {
  font-size: 20px;
}

.class-model__offers {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #16d8e4;
  font-size: 24px;
  font-weight: bold;
}

@media (max-width: 1000px) {
  .classes-list {
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: .35rem;
      height: .35rem; }

    &::-webkit-scrollbar-thumb {
      background: #e4e9f2;
      cursor: pointer;
      border-radius: .15625rem; }

    &::-webkit-scrollbar-track {
      background: #f7f9fc; }
  }
}

@media (max-width: 768px) {
  .classes-info__photo {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .classes-info {
    flex-direction: column;
    margin: 0 -15px;
  }
}