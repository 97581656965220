[class*="go-to"] {
  display: none;
}

.go-to {
  width: 50px;
  height: 50px;
  display: block;
  background-color: rgba(255, 255, 255, 0.95);
  color: #555;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: .3s ease-out;
  -o-transition: .3s ease-out;
  transition: .3s ease-out;
  z-index: 11;

  & i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 26px;
  }

  &:hover, &:focus:hover {
    text-decoration: none;
    color: #fff;
    background-color: #17a7ea;
  }

  &:focus {
    text-decoration: none;
    color: #555;
    background-color: rgba(255, 255, 255, 0.7);
  }
}

@media (max-width: 576px - 1) {
  .go-to {
    -webkit-transform: scale(0.8, 0.8);
    -ms-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
}