.jssocials-shares {
  margin: .2em 0
}

.jssocials-shares * {
  box-sizing: border-box
}

.jssocials-share {
  display: block;
  margin: 0 0 15px 0;
}

.jssocials-share:last-child {
  margin-right: 0
}

.jssocials-share-logo {
  width: 1em;
  vertical-align: middle;
  font-size: 1.5em
}

img.jssocials-share-logo {
  width: auto;
  height: 1em
}

.jssocials-share-link {
  display: inline-block;
  border: 2px solid #acacac;
  border-radius: 50%;
  color: #acacac;
  padding: 4px 4px;
  height: 47px;
  width: 47px;
  transition: background 200ms ease-in-out 0s, color 200ms ease-in-out 0s, border-color 200ms ease-in-out 0s;
  text-align: center;
  text-decoration: none;
  line-height: 1;

  & i {
    font-size: 26px;
    vertical-align: middle;
    line-height: 36px;
    color: #acacac;
  }

  & .icon-vk {
    position: relative;
    left: -4px
  }

  &:hover {
    border-color: #3abbbc;

    & i {
      color: #3abbbc;
    }
  }
}

.jssocials-share-link.jssocials-share-link-count {
  padding-top: .2em
}

.jssocials-share-link.jssocials-share-link-count .jssocials-share-count {
  display: block;
  font-size: .6em;
  margin: 0 -.5em -.8em -.5em
}

.jssocials-share-link.jssocials-share-no-count {
  padding-top: .5em
}

.jssocials-share-link.jssocials-share-no-count .jssocials-share-count {
  height: 1em
}

.jssocials-share-label {
  padding-left: .3em;
  vertical-align: middle
}

.jssocials-share-count-box {
  display: inline-block;
  height: 1.5em;
  padding: 0 .3em;
  line-height: 1;
  vertical-align: middle;
  cursor: default
}

.jssocials-share-count-box.jssocials-share-no-count {
  display: none
}

.jssocials-share-count {
  line-height: 1.5em;
  vertical-align: middle
}