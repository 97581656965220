// model main
.boat-model__body {
  display: flex;
}

.boat-model__request {
  max-width: 350px;
  flex: 0 0 350px;
  position: relative;
  top: -115px;
  min-height: 230px;
}

.boat-model__info {
  width: 100%;
  padding: 0 20px 0 0;
}

.boat-model__main {
  margin-right: 370px;
}

.boat-model__link-brand {
  color: #19a7e9;
}

.boat-model__link-location {
  color: #19a7e9;
}

// photo
.boat-model__header {
  margin: 0 0 20px 0;
}

.model-photo {
  cursor: pointer;
}

.model-header__container {
  display: flex;
  position: relative;
  min-height: 302px;
}

.model-header__photo-main {
  display: flex;
  width: 80%;

  & iframe {
    flex: 0 0 50%;
    max-width: 50%;
  }

  & a {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.model-header__photo-additional {
  width: 20%;
}

.model-header__photo-count {
  background: rgba(25, 167, 233, 0.8);
  position: absolute;
  color: #fff !important;
  top: 0;
  right: 0;
  padding: 10px 15px;
  display: block;

  &:hover, &:focus {
    text-decoration: none !important;
  }

  & i {
    font-size: 20px;
    margin-right: 5px;
  }

  & strong {
    margin-right: 5px;
  }
}

// info
.boat-model__links {
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px 0;

  & i {
    color: #09c4ff;
  }

  & a {
    text-decoration: underline;
  }

  & a:hover, & a:focus {
    text-decoration: none;
  }
}

.boat-model__link-brand {
  margin-right: 10px;
}

.boat-model__title {
  display: flex;
  align-items: center;
  margin: 0 0 25px 0;

  & h1 {
    font-size: 36px;
    color: #19a7e9;
    margin: 0 25px 0 0;
  }
}

.boat-model__compare {
  position: relative;
}

.boat-model__shortlist {
  margin: 0 25px 0 0;
}

.boat-model__accomodation {
  margin: 0 0 30px 0;
}

.boat-model__params {
  list-style: none;
  padding: 0;
  margin: 0;

  & li {
    display: inline-block;
    margin: 0 25px 10px 0;
  }
}

.boat-model__specification {
  background: #f5f5f5;
  padding: 30px;
  margin: 0 0 30px 0;
}

.model-specification__main {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 35px 0;

  & .model-specification__value {
    font-size: 24px;
    font-weight: bold;
  }
}

.model-specification__row {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.model-specification__row-2 {
  flex: 0 0 66.6666%;
  max-width: 66.6666%;
}

.model-specification__other {
  display: flex;
  flex-wrap: wrap;

  & .model-specification__row {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 15px 0;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 90%;
      height: 1px;
      background: #adadad;
      position: absolute;
      bottom: 21px;
    }
  }

  & .model-specification__title {
    background: #f5f5f5;
    position: relative;
    padding: 0 8px 0 0;
  }

  & .model-specification__value {
    font-weight: bold;
    background: #f5f5f5;
    position: relative;
    padding: 0 0 0 8px;
  }
}

// tabs
.boat-model__extension {
  position: relative;
  margin: 0 0 30px 0;
}

.boat-model__extension-blocks {
  & > .nav-tabs {
    border-bottom: 2px solid #e6e6e6;
  }
}

.model-block__title {
  display: none;
  font-size: 20px;

  & a {
    & .icon-down-open-mini {
      display: none;
    }

    &.collapsed {
      & .icon-down-open-mini {
        display: inline-block;
      }

      & .icon-up-open-mini {
        display: none;
      }
    }
  }
}

.btn-model-pdf {
  position: absolute;
  right: 0;
  color: #ff4747;
  text-decoration: underline;
  background: url(/img/pdf.png) no-repeat 0 -2px;
  padding: 2px 0 10px 36px;

  &:hover, &:focus {
    text-decoration: none;
    color: #d43838;
  }
}

// description
.boat-model__description {
  padding: 20px 0 0 0;
  overflow: hidden;
}

.boat-model__brief-expand {
  max-height: 12em;
  overflow: hidden;
  order: 0;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 4em;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, .25), rgba(255, 255, 255, .5), rgba(255, 255, 255, .75), rgba(255, 255, 255, 1));
  }
}

.boat-model__brief-expand_open {
  max-height: 100%;

  &:before {
    display: none;
  }
}

.boat-model__brief-more {
  margin: 10px 0 0 0;
  padding: 4px 10px;
  display: inline-block !important;
  background: #f5f5f5;
  color: #2e2e2e;
  cursor: pointer;
  text-decoration: underline;

  &:hover, &:focus {
    text-decoration: none;
    color: #2e2e2e;
  }
}

// deck
.boat-model__deck {
  padding: 25px 0 0 0;

  & .nav-tabs {
    border-bottom: 1px solid #e6e6e6;
    margin: 0 0 30px 0;
  }

  & .nav-tabs > li a {
    color: #969696;
    font-size: 16px;
    padding: 0 0 8px 0;
    margin: 0 20px 0 0;
    font-weight: normal;
  }

  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: #2e2e2e;
    border-bottom: 1px solid #2e2e2e;
  }
}

// offers
.boat-model-offers {
  padding: 0 0 40px 0;

  &:before {
    content: "";
    display: block;
    background: #ececec;
    width: calc(100vw - 6px);
    position: absolute;
    left: calc((1340px - 100vw) / 2);
    z-index: -1;
    height: calc(100% - 100vh);
  }
}

.boat-model-offers__form {
  padding: 40px 0 0 0;
}

.boat-model-offers__header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #e6e6e6;
  margin: 0 0 30px 0;
}

.boat-model-offers__header-tabs {
  display: flex;
}

.boat-model-offers__title {
  margin: 11px 20px 0 0;
  font-size: 22px;
  line-height: 90%;
}

.boat-model-offers__action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: -4px;

  .sort-select__value {
    background: #fff;
    border: none;
    height: 40px;
    border-radius: 1px;
  }

  .currency-select__value {
    height: 40px;
    border-radius: 1px;
  }
}

.boat-model-offers__sort {
  margin: 0 10px 0 0;
  display: flex;
  align-items: center;
}

.boat-model-offers__sort-label {
  margin-right: 5px;
}

.boat-model__sticky {
  position: sticky;
  top: 100px;
}

// request
.model-request {
  background: #002751;
  color: #fff;
  padding: 25px 25px 15px 25px;
}


.model-request-form {
  & .btn-request {
    font-size: 20px;
    font-weight: 400;
    background: #00d814;
    padding: 14px 12px;
  }
}

.model-request__form-title {
  margin: 0 0 20px 0;
}

.model-request__form-note {
  margin: 0 0 30px 0;
}

.model-request__privacy {
  margin: 0 0 10px 0;
}

@media (min-width: 768px) {
  .boat-model__extension-blocks .collapse {
    display: block !important;
  }
}

@media (max-width: 1400px) {
  .boat-model-offers {
    &:before {
      left: calc((100% - 100vw) / 2);
    }
  }
}

@media (max-width: 1330px) {
  .boat-model-offers__sort {
    & .sort-select__value {
      max-width: 200px;
    }
  }
}

@media (min-width: 1001px) {
  .boat-model__description-text.collapse {
    display: block;
    height: auto !important;
  }
}

@media (max-width: 1200px) {
  .boat-model__title {
    flex-wrap: wrap;

    & h1 {
      flex: 0 0 100%;
      max-width: 100%;
      margin: 0 0 20px 0;
    }
  }

  .model-specification__main {
    display: block;
  }

  .model-specification__other {
    display: block;

    & .model-specification__title {
      background: #fff;
    }

    & .model-specification__value {
      background: #fff;
    }
  }

  .model-specification__row {
    max-width: inherit;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 0 15px 0 !important;
    position: relative;

    & .model-specification__title {
      position: relative;
      background: #f5f5f5;
      padding: 0 8px 0 0;
    }

    & .model-specification__value {
      position: relative;
      background: #f5f5f5;
      font-size: 16px;
      font-weight: bold;
      padding: 0 0 0 8px;
    }

    &:before {
      content: "";
      display: block;
      width: 90%;
      height: 1px;
      background: #adadad;
      position: absolute;
      bottom: 21px;
    }
  }

  .boat-model__body {
    flex-wrap: wrap;
  }

  .boat-model__info {
    order: 2;
    padding: 0;
  }

  .boat-model__request {
    max-width: inherit;
    flex: 0 0 auto;
    order: 1;
    position: static;
    top: 0;
    margin: 0 0 30px 0;
  }

  .boat-model-offers__sort {
    & .sort-select__value {
      max-width: 100%;
    }
  }

  .boat-model__main {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .model-header__container {
    display: block;
  }

  .model-header__photo-main {
    display: block;
    width: auto;
    margin: 0 -15px;
    max-height: 100%;
  }

  .model-header__photo-main_video {
    max-height: 302px;
  }

  .model-header__photo-main iframe {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .model-header__photo-main .model-photo {
    max-width: inherit;
    flex: 0 0 100%;

    & img {
      width: 100%;
    }
  }

  .model-photo_second {
    display: none !important;
  }

  .model-header__photo-additional {
    width: auto;
    margin: 0 -15px;
    display: flex;

    & a {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
  }

  .model-header__photo-count {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .boat-model__brief-expand {
    max-height: 100%;

    &:before {
      display: none;
    }
  }

  .boat-model__brief-more {
    display: none !important;
  }

  .boat-model__body {
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  .boat-model__request {
    width: 100%;
    max-width: inherit;
    flex: 0 0 auto;
    order: 1;
    position: static;
    top: 0;
    margin: 0 0 30px 0;
  }

  .boat-model__main {
    display: flex;
    flex-direction: column;
  }

  .boat-model__header {
    margin: 0 0 20px 0;
  }

  .boat-model__title {
    order: 1;
    margin: 0 0 10px 0;

    & h1 {
      font-size: 30px;
      margin: 0 0 10px 0;
    }
  }

  .model-request__form-title {
    font-size: 24px;
  }

  .boat-model__accomodation {
    order: 3;
    margin: 0 0 20px 0;
  }

  .boat-model__shortlist {
    margin: 0 0 5px 0;
  }

  .boat-model__links {
    display: block;
    margin: 0 0 20px 0;
    order: 2;
  }

  .boat-model__links-main {
    margin: 0 0 10px 0;
  }

  .boat-model__title {
    display: block;
  }

  .boat-model__specification {
    background: #fff;
    padding: 0 15px 20px 15px;
    border-bottom: 2px solid #e6e6e6;
    margin: 0 0 20px 0;
  }

  .boat-model__extension {
    margin: 0;
  }

  .boat-model__extension-pdf {
    border-bottom: 2px solid #e6e6e6;
    padding: 0 15px 20px 15px;
    margin: 0 0 20px 0;
  }

  .btn-model-pdf {
    position: static;
  }

  .boat-model__extension-blocks {
    padding: 0 15px;

    & > .nav-tabs {
      display: none;
    }

    & > .tab-content > div {
      display: block;
      margin: 0 -15px;
      width: auto;
      padding: 0 15px;
      border-bottom: 2px solid #e6e6e6;
    }
  }

  .model-specification__row {
    & .model-specification__title {
      background: #fff;
    }

    & .model-specification__value {
      background: #fff;
    }
  }

  .model-block__title {
    display: block;
    margin: 20px 0;
  }

  .boat-model-offers {
    padding: 0 15px;
  }

  .boat-model-offers__form {
    padding: 30px 0 0 0;
  }

  .boat-model-offers__header {
    display: block;
    border-bottom: none;
    margin: 0 0 10px 0;
  }

  .boat-model-offers__header-tabs {
    display: block;
  }

  .boat-model-offers__title {
    margin: 0 0 20px 0;
  }

  .boat-model-offers__type {
    .nav-tabs {
      margin: 0 0 20px 0;
      border-bottom: 1px solid #e6e6e6;
    }

    .nav-tabs > li > a {
      font-size: 20px;
      padding: 0 0 10px 0;
    }
  }

  .boat-model-offers__action .sort-select__value {
    background: #f5f5f5;
  }
}