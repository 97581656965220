
.icon-plus:before { content: '\e800'; } /* '' */
.icon-user-outline:before { content: '\e801'; } /* '' */
.icon-right-open:before { content: '\e802'; } /* '' */
.icon-left-open:before { content: '\e803'; } /* '' */
.icon-down-open-mini:before { content: '\e804'; } /* '' */
.icon-left-open-mini:before { content: '\e805'; } /* '' */
.icon-right-open-mini:before { content: '\e806'; } /* '' */
.icon-up-open-mini:before { content: '\e807'; } /* '' */
.icon-arrows-ccw:before { content: '\e808'; } /* '' */
.icon-search:before { content: '\e809'; } /* '' */
.icon-stopwatch:before { content: '\e80a'; } /* '' */
.icon-plus-circled:before { content: '\e80b'; } /* '' */
.icon-ok:before { content: '\e80c'; } /* '' */
.icon-home:before { content: '\e80d'; } /* '' */
.icon-jet-ski:before { content: '\e80e'; } /* '' */
.icon-power-boats:before { content: '\e80f'; } /* '' */
.icon-globe:before { content: '\e810'; } /* '' */
.icon-phone:before { content: '\e811'; } /* '' */
.icon-star:before { content: '\e812'; } /* '' */
.icon-length:before { content: '\e813'; } /* '' */
.icon-camera:before { content: '\e814'; } /* '' */
.icon-stats:before { content: '\e815'; } /* '' */
.icon-right-dir-big:before { content: '\e816'; } /* '' */
.icon-left-dir-big:before { content: '\e817'; } /* '' */
.icon-exit-symbol:before { content: '\e818'; } /* '' */
.icon-icon-comapare:before { content: '\e819'; } /* '' */
.icon-dollar:before { content: '\e81a'; } /* '' */
.icon-time-is-money:before { content: '\e81b'; } /* '' */
.icon-clock:before { content: '\e81c'; } /* '' */
.icon-calendar:before { content: '\e81d'; } /* '' */
.icon-two-way:before { content: '\e81e'; } /* '' */
.icon-passenger:before { content: '\e81f'; } /* '' */
.icon-filter:before { content: '\e820'; } /* '' */
.icon-website:before { content: '\e821'; } /* '' */
.icon-speaker:before { content: '\e822'; } /* '' */
.icon-open-email:before { content: '\e823'; } /* '' */
.icon-motor-yachts:before { content: '\e824'; } /* '' */
.icon-directions:before { content: '\e825'; } /* '' */
.icon-sailing-yachts:before { content: '\e826'; } /* '' */
.icon-boat:before { content: '\e827'; } /* '' */
.icon-steering-wheel:before { content: '\e828'; } /* '' */
.icon-tag:before { content: '\e829'; } /* '' */
.icon-plus-circle:before { content: '\e82a'; } /* '' */
.icon-clear-filter:before { content: '\e82b'; } /* '' */
.icon-wallet:before { content: '\e82c'; } /* '' */
.icon-living-room:before { content: '\e82d'; } /* '' */
.icon-anchor:before { content: '\e82e'; } /* '' */
.icon-key:before { content: '\e82f'; } /* '' */
.icon-bed:before { content: '\e830'; } /* '' */
.icon-boat-length:before { content: '\e831'; } /* '' */
.icon-brochure:before { content: '\e832'; } /* '' */
.icon-cabin:before { content: '\e833'; } /* '' */
.icon-question:before { content: '\e834'; } /* '' */
.icon-crew:before { content: '\e835'; } /* '' */
.icon-up-micro:before { content: '\e836'; } /* '' */
.icon-cancel:before { content: '\e837'; } /* '' */
.icon-down-micro:before { content: '\e838'; } /* '' */
.icon-icon-bathroom:before { content: '\e839'; } /* '' */
.icon-magnify-hover:before { content: '\e83a'; } /* '' */
.icon-ocean:before { content: '\e83b'; } /* '' */
.icon-overview:before { content: '\e83c'; } /* '' */
.icon-walkway:before { content: '\e83d'; } /* '' */
.icon-photos:before { content: '\e83e'; } /* '' */
.icon-price:before { content: '\e83f'; } /* '' */
.icon-shortlist:before { content: '\e840'; } /* '' */
.icon-shortlist-hover:before { content: '\e841'; } /* '' */
.icon-user:before { content: '\e842'; } /* '' */
.icon-left-arrow-circle:before { content: '\e843'; } /* '' */
.icon-right-arrow-circle:before { content: '\e844'; } /* '' */
.icon-list:before { content: '\e845'; } /* '' */
.icon-cog:before { content: '\e846'; } /* '' */
.icon-compass:before { content: '\e847'; } /* '' */
.icon-weight-plus:before { content: '\e848'; } /* '' */
.icon-logout:before { content: '\e849'; } /* '' */
.icon-weight-minus:before { content: '\e84a'; } /* '' */
.icon-list-big:before { content: '\e84b'; } /* '' */
.icon-cogs:before { content: '\e84c'; } /* '' */
.icon-link:before { content: '\e84d'; } /* '' */
.icon-award:before { content: '\e84e'; } /* '' */
.icon-pencil:before { content: '\e84f'; } /* '' */
.icon-trash:before { content: '\e850'; } /* '' */
.icon-spin:before { content: '\e851'; } /* '' */
.icon-thumbs-up:before { content: '\e852'; } /* '' */
.icon-thumbs-down:before { content: '\e853'; } /* '' */
.icon-mobile:before { content: '\e854'; } /* '' */
.icon-rss:before { content: '\e855'; } /* '' */
.icon-close:before { content: '\e856'; } /* '' */
.icon-airport:before { content: '\e857'; } /* '' */
.icon-up-dir:before { content: '\e858'; } /* '' */
.icon-down-dir:before { content: '\e859'; } /* '' */
.icon-star-empty:before { content: '\e85a'; } /* '' */
.icon-pencil-circled:before { content: '\e85b'; } /* '' */
.icon-thumb-up:before { content: '\e85c'; } /* '' */
.icon-thumb-down:before { content: '\e85d'; } /* '' */
.icon-asterisk:before { content: '\e85e'; } /* '' */
.icon-lightbulb:before { content: '\e85f'; } /* '' */
.icon-comment:before { content: '\e860'; } /* '' */
.icon-cart:before { content: '\e861'; } /* '' */
.icon-comment-alt:before { content: '\e862'; } /* '' */
.icon-eye:before { content: '\e863'; } /* '' */
.icon-vk:before { content: '\e864'; } /* '' */
.icon-review:before { content: '\e865'; } /* '' */
.icon-email:before { content: '\e866'; } /* '' */
.icon-document-file:before { content: '\e867'; } /* '' */
.icon-email-outline:before { content: '\e868'; } /* '' */
.icon-world:before { content: '\e886'; } /* '' */
.icon-radio:before { content: '\e88e'; } /* '' */
.icon-globe-link:before { content: '\e88f'; } /* '' */
.icon-location:before { content: '\f031'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-user-filter:before { content: '\f0b0'; } /* '' */
.icon-sort:before { content: '\f0dc'; } /* '' */
.icon-sort-down:before { content: '\f0dd'; } /* '' */
.icon-sort-up:before { content: '\f0de'; } /* '' */
.icon-unlink:before { content: '\f127'; } /* '' */
.icon-link-ext-alt:before { content: '\f14c'; } /* '' */
.icon-doc-text-inv:before { content: '\f15c'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-fax:before { content: '\f1ac'; } /* '' */
.icon-lifebuoy:before { content: '\f1cd'; } /* '' */
.icon-paper-plane:before { content: '\f1d8'; } /* '' */
.icon-user-circle:before { content: '\f2bd'; } /* '' */
.icon-user-circle-o:before { content: '\f2be'; } /* '' */
.icon-user-o:before { content: '\f2c0'; } /* '' */
.icon-id-card:before { content: '\f2c2'; } /* '' */
.icon-id-card-o:before { content: '\f2c3'; } /* '' */
.icon-facebook:before { content: '\f30c'; } /* '' */
.icon-instagram:before { content: '\f31f'; } /* '' */