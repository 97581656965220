.contacts-header {
  padding: 25px 0 25px 0;
  background: #f0f5fa;
  margin: 0;
}

.contacts-body {
  display: flex;
  justify-content: space-between;
}

.contacts-office {
  padding: 55px 30px 20px 0;
}

.contacts-office__title {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 30px 0;
}

.contacts-office__info {
  list-style: none;
  margin: 0;
  padding: 0;

  & li {
    margin: 0 0 30px 0;
    padding-left: 30px;
    position: relative;

    & i {
      position: absolute;
      top: 0;
      left: 0;
    }

    & a {
      color: #000;
    }
  }
}

.contacts-map {
  flex: 1 0 65%;
  max-width: 65%;

  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 500px;
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .contacts-body {
    flex-wrap: wrap;
  }

  .contacts-office {
    width: 100%;
    padding: 25px 0 0 0;
  }

  .contacts-map {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .contacts-map {
    max-width: inherit;
    width: auto;
    margin: 0 -15px;
  }
}