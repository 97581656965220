.main-content {
  height: 420px;
  padding: 150px 0 0 0;

  & .container {
    position: relative;
    height: 100%;
  }

  &.bg {
    background-color: #1D4578;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAALCAIAAACyKEmDAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFVElEQVR42k2Sh1eTVxjG3y/F04posbWoQB2IYciQJSMBAyRkhwSBkAGBkECIEMgekPFlkEUYEoQAWnqkuJhBRBTtv9b7BY72nN95znPPfdcdcI02ntUycaNVn0035DAMt9qMd5imuyxTHst0j40w57PN9zkIC5ljLuAiLIVcSxHPUpjiuznzZ8siLqFkrgUFI0W5+RzLPTZBHsuMKqMWt5nGP5mm3DYjaprNMNxoNVxvNlxrnsikjV9q0kF2G7F9h23OQ8lcK5lnK+DbCvm2IoHtQTvCXoIQ2ksRIkcZwWS5yHFOx+TD/yM61/IUZT9wlAodJUJHcbujQmQvFtgL+fYCvp3Mt93n2e5xrXc51ttsyy2WOZdpusEwZrUaoBh1JRpMVXQ6K7uc1d3uarG7psfzqMdTK/HUSfB6Kd4g9VJk3gYZoVQ5wpfSM/ODxnPjp8h9BDJfQ4p6qbdOitL9qFqrzNkow6vEeLXYg6jq9pR3usueuEo6nMWiqSLh5H2BPY9nA4oMp/Z6G/v8TYoAbSDQopxmqEJMdbhNFWKpw5zhMHcoxXBEoInyNRHecISnifBTIMPVRM/gaKK8FIQfRssZ9kgMwdLMIOiDQapkkjnob5VNMXqddHWEqpiulftr5QGqItjQN13Xi5aBGqm/Uux9KMaBpgzQVUHe02i7blY4MfdEP88fnRWNzUuMi2LDM7FhQW5elFsWpebFLv2CxLTYZ13qtcaRKmxLvZYlwpwTR5ED9udDzoTasaSyPRswzQ8Y5mSjYbZsisqbeMQca+l28Ad97UpcZV4QaYLUbntbn6dLHxcaljmjz5gjc4zhWIs6RlFMQxHbVMCzlXW56mU4o9/bpvQ/Ertqe3CmKtzUH6L1hziaGF87K0RHV6ObiIn1iwO25X7bisK6rJlKKB2JjvG41LCEvDHwolmOk5mOQqH7eqvp91bjrwxjtmS6XDv/h9CZyXZk0K3pNer0ysHM+iGKKsTRxahDbtaAuYQiyiltySlpyn5AvVVGyyLXQSHTdJNhyW6z5rLt5PYpcoe7WuqrkAWoyjBlMFqtiNT0z1QOzFar5hqHFx6PLFK1cYr2eZ12pUqbqB1dpY6t1o+u1o2t1evWmdaNpvHExSYLFOigAv0PH9CDQJsEQQSkC9AZgsfjwHGCKAh9CeD5QDQDknimevYK5UnGQ8GVqvbLFcJLpbxfywUAnXGQrIBiHVQbMPIKRjdJuq00/ZufTW/Tze/Sre8v27cJHDuXp/YuOffTXfsZ7v2rnoOrnmQWnryGJ7O8yet4MtebzA8c3vQfZvsOL/LcUDMKjWZossFjE7BcwPEA3QK1amgxAcMGbDdGt0ML2rVCsx0T4CRxDPo3QLEB0lXoigN0r4B8HZR/g+oVjGzB+FvQvwfzDlh2wb4PriTgH8BzSKjvCPMfkYLHWOgTFiI0LXKSFj1B5qfQ8S8pD8FPqeAjQt0ofQ/DDzDvNthfgeXFBfPaHcdqjjWBGV5ixg3QrYNmHbNsgeENjG2B7jVoN0H5EhRrAB1LIE6A/GVqrNegewf6bTDugHUfJpPg+kDg/Qj+YwzhPcYCx1jwBEGKfMEiX0hRQr8biJ5C+BQipxD6QpjQZ0DBkRMIoCJ7F/w7hdHd/PAuybMHzl2Y3AbrNsm5izn3wbQN46/h6SYM/gV9aCz+HHQtQ88aqDZh+B/QvYexd2DaBetBarJDAvwYXEeE8XyEwAlEvxLMID3Fol+xma9Y7Bs29y+CNPuNmGbmGzFZOAWaL/iZOBh+CN6DjMDeb8G9NBxV3iMwo8nQFezCxFvQbhEfCT2idOU/Xp9Q8i6jwvAAAAAASUVORK5CYII=");
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.bg-enhanced {
    background-image: url('/img/bg.jpg');
  }
}

.home-search__form {
  margin: 0 auto 55px auto;
  max-width: 880px;
  position: relative;

  & .form-control {
    height: 60px;
    border: none !important;

    &::-moz-placeholder {
      color: #7a8fb1 !important;
    }

    &::-webkit-input-placeholder {
      color: #7a8fb1 !important;
    }

    &:-ms-input-placeholder {
      color: #7a8fb1 !important;
    }

    &::-ms-input-placeholder {
      color: #7a8fb1 !important;
    }

    &::placeholder {
      color: #7a8fb1 !important;
    }
  }

  & .btn {
    text-transform: uppercase;
    font-size: 20px;
    border-radius: 0;
    background: #16d8e4;
    border: none;
    padding: 6px 40px;
    transition: background-color 0.3s;

    &:hover, &:focus {
      background: #13a4b0;
    }
  }
}

.home-search__form-group {
  display: flex;

  & input {
    margin: 0 10px 0 0;
    padding: 5px 15px 6px 15px;
  }
}

@supports (background-image: filter(url('i.jpg'), blur(1px))) {
  .main-content {
    &.bg {
      transform: translateZ(0);
    }

    &.bg-enhanced {
      animation: sharpen 5s both;
    }
  }

  @keyframes sharpen {
    from {
      background-image: filter(url(/img/bg.jpg), blur(20px));
    }
    to {
      background-image: filter(url(/img/bg.jpg), blur(0px));
    }
  }
}

// titles
.home-title {
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .6);
  margin: 0 0 20px 0;
  font-size: 54px;
  font-weight: 700;
  text-align: center;
}

.home-title-second {
  color: #fff;
  font-size: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .6);
  text-align: center;
  font-weight: 400;
  margin: 0 0 30px 0;
}

// boat random
.main-boats {
  padding: 20px 0 25px 0;
}

.random-boat__note {
  margin: 0 0 20px 0;
}

// classes models
.main-classes {
  padding: 20px 0 20px 0;
}

.princess-classes {
  padding: 50px;
  border: 5px solid #eff3f7;
}

.main-classes__note {
  margin: 0 0 10px 0;
}

.princess-classes__title {
  text-transform: uppercase;
  font-size: 26px;
  font-weight: bold;
  margin: 0 0 20px 0;
  color: #001d4b;
}

.main-classes__variants {
  padding: 0 0 0 15px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  & li {
    max-width: 50%;
    flex: 0 0 50%;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: -15px;
      top: 10px;
      background: #001d4b;
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }
}

.princess-classes__category {
  list-style: none;
  padding: 0;
  margin: 30px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.princess-category {
  flex: 0 0 25%;
  max-width: 25%;
}

.princess-category__title {
  text-transform: uppercase;
  color: #169ce4;
  font-weight: bold;
  font-size: 16px;
  margin: 0 50px 10px 0;
  position: relative;

  & a {
    background: #fff;
    z-index: 1;
    position: relative;
    padding-right: 10px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -2px;
    height: 2px;
    background: #169ce4;
  }
}

.princess-discontinued__title {
  color: #7a8fb1;
  text-transform: uppercase;
  position: relative;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 20px 0;

  & span {
    background: #fff;
    z-index: 1;
    position: relative;
    padding-right: 10px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -2px;
    height: 2px;
    background: #7a8fb1;
  }
}

.princess-discontinued__models {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  & li {
    flex: 0 0 12.5%;
    max-width: 12.5%;
    color: #7a8fb1;
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
  }

  & a {
    color: #7a8fb1;
    font-weight: bold;
    max-width: 80%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.princess-category__note {
  color: #7a8fb1;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 15px 0;
}

.princess-category__models {
  list-style: none;
  padding: 0;
  margin: 0 0 40px 0;
  display: flex;
  flex-flow: column wrap;
  height: 105px;

  & li {
    margin: 0 0 10px 0;
  }

  & a {
    font-weight: bold;
    color: #001d4b;

    &:hover, &:focus {
      color: #002771;
      text-decoration: none;
    }
  }
}

.block-text {
  margin: 0 0 20px 0;
}

.block-text__header {
  text-transform: uppercase;
  font-size: 26px;
  line-height: 34px;
  font-weight: bold;
  color: #2e2e2e;
  margin: 20px 0 24px 0;
}

.block-text__column {
  column-count: 2;
  column-gap: 30px;
  text-align: justify;
}

@media (max-width: 1000px) {
  .sale-search__row {
    flex-wrap: wrap;
  }

  .sale-search__column {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .sale-search__column:nth-child(2) {
    &:after {
      display: none;
    }
  }

  .princess-category {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }

  .princess-discontinued__models li {
    flex: 0 0 16.666%;
    max-width: 16.666%;
  }
}

@media (max-width: 768px) {
  .main-content {
    padding: 130px 0 0 0;
  }

  .home-title {
    font-size: 30px;
    line-height: 40px;
    margin: 0 0 5px 0;
  }

  .home-title-second {
    font-size: 24px;
    line-height: 40px;
  }

  .main-boats {
    padding: 20px 0 20px 0;
    margin: 0;
  }

  .block-text__column {
    column-count: 1;
  }

  .sale-search__form {
    margin: 30px 0 0 0;
  }

  .sale-search__column {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 0 6px 0;
  }

  .sale-search__column:after {
    display: none;
  }

  .princess-category {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .princess-discontinued__models li {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .main-classes__variants {
    & li {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .main-boats {
    padding: 20px 0;
  }
}

@media (max-width: 576px) {
  .home-title {
    font-size: 30px;
  }

  .main-content {
    padding: 50px 0 0 0;
  }

  .main-boats {
    padding: 20px 0;
  }

  .princess-classes {
    padding: 20px 0;
    margin: 0;
    border-left: 0;
    border-right: 0;
  }

  .princess-category {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .princess-discontinued__models li {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .princess-discontinued__title {
    line-height: 24px;

    &:before {
      display: none;
    }
  }

  .home-search__form-group {
    flex-direction: column;

    & .form-control {
      margin: 0 0 10px 0;
      height: 50px;
    }

    & .btn {
      height: 50px;
    }
  }

  .main-content {
    height: 366px;
  }

  .sale-search__btn {
    & .btn {
      display: block;
      width: 100%;
      margin: 0 0 10px 0;
    }

    & .btn + .btn {
      margin: 0;
    }
  }

  .princess-category__models {
    margin: 0 0 10px 0;
    height: auto;
  }

  .main-classes {
    & .princess-classes {
      padding: 20px;
      margin: 0 -15px;
    }
  }
}