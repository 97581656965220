// Base for label styling
.form-control__checkbox {
  & [type="checkbox"] {
    position: absolute;
    left: -9999px;

    &:not(:checked) + label,
    &:checked + label {
      position: relative;
      padding-left: 27px;
      cursor: pointer;
      line-height: 100%;
      min-height: auto;
    }

    // checkbox aspect
    &:not(:checked) + label:before,
    &:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: -2px;
      width: 18px;
      height: 18px;
      border: 2px solid #b7b7b7;
      border-radius: 2px;
    }

    &:checked + label:before {
      background-color: #09c4ff;
      border-color: #09c4ff;
      transition: all 0.3s;
    }

    // checked mark aspect
    &:not(:checked) + label:after,
    &:checked + label:after {
      content: '';
      width: 10px;
      height: 6px;
      position: absolute;
      top: 2px;
      left: 4px;
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transition: all .2s;
    }

    // checked mark aspect changes
    &:not(:checked) + label:after {
      opacity: 0;
      transform: rotate(-45deg) scale(0);
    }

    &:checked + label:after {
      opacity: 1;
      transform: rotate(-45deg) scale(1);
    }

    // disabled checkbox
    &:disabled:not(:checked) + label:before,
    &:disabled:checked + label:before {
      box-shadow: none;
      border-color: rgba(#d2d2d2, 0.5);
    }

    &:disabled:checked + label:after {
      color: #999;
    }

    &:disabled + label {
      color: #aaa;
    }

    // accessibility
    &:not(:checked) + label:hover:before {
      background-color: #09c4ff;
      border-color: #09c4ff;
    }

    &[readonly] + label:before {
      opacity: 0.3;
      background: #CDD2CD;
      border: 1px solid #fff;
    }
  }
}

.form-control__radio {
  input[type="radio"] {
    position: absolute;
    opacity: 0;

    + label {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      padding-left: 0;
      min-height: auto;
      line-height: 100%;
    }

    + label:before {
      content: '';
      position: relative;
      top: -0.1rem;
      margin-right: 4px;
      vertical-align: top;
      text-align: center;
      background: #fff;
      border-radius: 100%;
      display: block;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      transition: all .2s;
      border: 2px solid #b7b7b7;
    }

    &:checked + label:before {
      background-color: #09c4ff;
      border-color: #09c4ff;
    }

    &:checked + label:after {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -6px;
      left: 6px;
      display: block;
      border-radius: 100%;
      background-color: #fff;
      width: 8px;
      height: 8px;
    }

    &:disabled:checked + label:after {
      background-color: #708296;
    }

    &:focus + label:before {
      outline: none;
      border-color: #fff;
    }

    &:disabled + label:before {
      border-color: #708296;
      background: #708296;
    }

    & + label:empty:before {
      margin-right: 0;
    }

    &:checked:focus + label:before,
    &:not(:checked):focus + label:before,
    & + label:hover:before {
      background-color: #09c4ff;
      border-color: #09c4ff;
    }

    &:checked:disabled + label:before,
    &:not(:checked):disabled + label:before {
      border-color: #708296;
    }
  }
}