.sort-select__value {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background: #f5f5f5;
  border: 1px solid #ececec;
  color: #333 !important;
  border-radius: 2px;
  padding: 6px 12px;
  min-width: 80px;
  width: 100%;

  & .sort-value {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover, &:focus {
    text-decoration: none;
    background: #f4f4f4;
  }
}

.sort-select {
  & .dropdown-menu {
    min-width: 100px;

    & li {
      float: left;
      cursor: pointer;
    }

    & a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.42857143;
      color: #333;
      white-space: nowrap;
    }
  }

  & .icon-up-open-mini {
    display: none;
  }

  &.open {
    & .icon-up-open-mini {
      display: inline-block;
    }

    & .icon-down-open-mini {
      display: none;
    }
  }

  &.open .sort-select__value {
    background: #fff;
    border: 1px solid #aaa;
  }

  & .dropdown-menu {
    min-width: 200px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }

  & .dropdown-menu > li {
    display: block;
    width: 100%;
  }

  & .dropdown-menu > li > a {
    padding: 8px 20px;
    border-radius: 2px;
    display: block;
  }

  & .dropdown-menu > li > a:hover, & .dropdown-menu > li > a:focus {
    background-color: #eff8f8;
  }
}