.w-select__value {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 10px;
  color: #999;

  &:hover, &:focus {
    text-decoration: none;
    color: #999;
  }
}

.w-select {
  & .icon-up-dir, & .icon-up-open-mini {
    display: none;
  }

  &.open {
    & .icon-up-dir, & .icon-up-open-mini {
      display: inline-block;
    }

    & .icon-down-dir, & .icon-down-open-mini {
      display: none;
    }
  }

  & .dropdown-menu {
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    max-width: 280px;
    font-size: 14px;
  }

  & .dropdown-menu > li > a {
    padding: 8px 10px;
    border-radius: 2px;
    display: flex;

    & span {
      margin-right: 5px;
    }
  }

  & .dropdown-menu > li > a:hover, & .dropdown-menu > li > a:focus {
    background-color: #f0f0f0;
  }
}

.b-error {
  display: none;
}