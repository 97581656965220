.boat-row__more {
  opacity: 0;
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 30px;
}

.boat-row__added {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
  color: #c6c6c6;
}

.boat-row {
  margin: 0 0 10px 0;
  display: flex;
  align-items: stretch;
  position: relative;
  transition: all 0.3s;
  text-decoration: none !important;
  color: #2e2e2e;

  &:hover {
    & .boat-row__body {
      background: #f5f5f5;
    }

    & .boat-row__more {
      opacity: 1;
    }

    & .boat-row__added {
      display: none;
    }
  }
}

.boat-row__price {
  text-align: right;
}

.boat-row__price-from {
  color: #909090;
}

.boat-row__price-period {
  color: #909090;
}

.boat-row__price-value {
  font-size: 20px;
  color: #17a7ea;
  font-weight: 700;
  white-space: nowrap;
  margin-left: 50px;
}

.boat-row__photo {
  width: 360px;
  min-height: 150px;
  flex-shrink: 0;

  img {
    // width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.boat-row__body {
  width: 100%;
  background: #fff;
  border: 1px solid #ececec;
  padding: 20px;
  overflow: hidden;
}

.boat-row__info {
  display: flex;
  justify-content: space-between;
  padding: 0 0 10px 0;
}

.boat-row__info-main {
  width: 100%;
}

.boat-row__info-title {
  font-weight: 700;
  color: #001d4b;
  font-size: 20px;
  text-transform: uppercase;
  margin: 0 0 5px 0;
}

.boat-row__info-title_brand {
  color: #909090;
}

.boat-row__info-place {
  color: #001d4b;
  margin: 0 0 5px 0;
}

.boat-row__info-specification {
  list-style: none;
  padding: 0;
  margin: 0 0 15px 0;
  color: #169ce4;
  font-size: 14px;

  & li {
    display: inline-block;

    &:before {
      content: "|";
      padding-right: 5px;
    }
  }

  & li:last-of-type {
    margin-right: 0;

     &:after {
      content: "|";
      padding-left: 10px;
     }
  }
}

.boat-row__info-opportunity {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #7a8fb1;
  font-size: 14px;

  & i {
    font-size: 20px;
  }

  & li {
    display: inline-block;
    margin-right: 5px;

    & span {
      font-weight: 700;
      margin-right: 3px;
    }
  }
}

@media (max-width: 1280px) {
  .boat-row__body {
    padding: 10px;
  }

  .boat-row__photo {
      width: 315px;
  }

  .boat-title {
    font-size: 30px;
    margin-top: 0;
  }
}


@media (max-width: 767px) {
  .boat-row {
    display: block;
  }

  .boat-row__photo {
    width: 100%;

    & img {
      width: 100%;
    }
  }

  .boat-row__body {
    padding: 10px 10px 30px 10px;
  }
}

@media (max-width: 575px) {
  .boat-title {
    font-size: 24px;
  }

  .boat-result__count {
    font-size: 14px;
  }

  .boat-row__price-period {
    margin-left: 10px;
  }
}

@media (max-width: 480px) {
  .boat-row__info {
    flex-direction: column;
  }

  .boat-row__price {
    display: flex;
    order: -1;
  }

  .boat-row__price-value {
    margin-left: 10px;
  }

  .boat-row__info-title_brand {
    font-size: 13px;
    line-height: 100%;
  }

  .boat-row__info-specification {
    white-space: nowrap;

    & li {
      font-size: 13px;
    }

    & li:first-of-type:before {
      display: none;
    }

    & li:last-of-type:after {
      display: none;
    }
  }

  .broker-head__image {
    margin: 0 auto 20px auto;
  }

  .boat-row__info-opportunity {
    white-space: nowrap;

    & i {
      font-size: 16px;
    }

    & li {
      font-size: 13px;
    }

    & li span {
      margin-right: -1px;
    }
  }
}