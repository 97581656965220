.currency-select__value {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  background: #17a7ea;
  color: #fff !important;
  border-radius: 2px;
  padding: 8px 12px;
  width: 80px;

  &:hover, &:focus {
    text-decoration: none;
    background: #1579b1;
  }
}

.currency-select {
  & .dropdown-menu {
    min-width: 100px;

    & li {
      float: left;
      cursor: pointer;
    }

    & a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.42857143;
      color: #333;
      white-space: nowrap;
    }
  }

  & .icon-up-open-mini {
    display: none;
  }

  &.open {
    & .icon-up-open-mini {
      display: inline-block;
    }

    & .icon-down-open-mini {
      display: none;
    }
  }

  &.open .currency-select__value {
    background: #1579b1;
  }

  & .dropdown-menu {
    max-width: 100px;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }

  & .dropdown-menu > li > a {
    padding: 8px 20px;
    border-radius: 2px;
  }

  & .dropdown-menu > li > a:hover, & .dropdown-menu > li > a:focus {
    background-color: #eff8f8;
  }
}

.currency-icon-select {
  & .dropdown-menu {
    max-width: 40px;
    min-width: auto;
    border-radius: 0;
    border: none;
    margin: 8px 0 0 0;

    & li {
      display: block;
    }

    & li a {
      padding: 8px 6px;
      cursor: pointer !important;
    }
  }

  & .dropdown-menu > li > a:hover, & .dropdown-menu > li > a:focus {
    background-color: #eff8f8;
  }
}

.currency-icon {
  display: inline-block;
  color: #fff;
  font-size: 18px;
  background: #2e2e2e;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
}

.currency-icon-valute {
  margin: 0 6px;
}