@media (min-width: 768px) {
  .navbar-collapse.collapse {
    display: flex !important;
    justify-content: space-between;

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }
}

.header {
  .navbar {
    margin: 0;
    background: #fff;
    box-shadow: -1px 4px 11px 0 rgba(0,0,0,.1);
  }

  & .navbar-toggle {
    position: relative;
    width: 28px;
    height: 25px;
    vertical-align: middle;
    fill: none;
    float: left;
    padding: 0;
    margin-top: 13px;

    & .icon-bar {
      position: absolute;
      left: 0;
      margin: 0;
      display: block;
      width: 100%;
      height: 4px;
      -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1.000);
      -moz-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1.000);
      -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1.000);
      transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1.000);
      -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
      -moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
      -o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
    }

    & .icon-bar:nth-child(1) {
      top: 0;
    }

    & .icon-bar:nth-child(2) {
      top: 50%;
      margin-top: -2px;
    }

    & .icon-bar:nth-child(3) {
      bottom: 0;
    }

    &[aria-expanded="true"] {
      & .icon-bar:nth-child(1) {
        -webkit-transform: translateY(10px) translateX(0) rotate(45deg);
        -moz-transform: translateY(10px) translateX(0) rotate(45deg);
        transform: translateY(10px) translateX(0) rotate(45deg);
      }

      & .icon-bar:nth-child(2) {
        opacity: 0;
      }

      & .icon-bar:nth-child(3) {
        -webkit-transform: translateY(-9px) translateX(0) rotate(-45deg);
        -moz-transform: translateY(-9px) translateX(0) rotate(-45deg);
        transform: translateY(-9px) translateX(0) rotate(-45deg);
      }
    }
  }

  & .navbar-toggle {
    & .icon-bar {
      background-color: #072B51;
    }
  }

  .navbar-brand {
    margin-top: 0;
    padding: 12px 15px;
    height: auto;

    & img {
      width: 154px;
      height: 28px;
    }
  }
}
.navbar-header {
  & .navbar-brand {
    display: none;
  }
}

.navbar-collapse {
  padding: 0 !important;
  position: relative;
  z-index: 2;
}

.logo {
  display: inline-block;
  width: 224px;
  height: 41px;
  background: url(/img/logo.svg);
  background-size: cover;
  margin: 15px 30px 5px 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & .navbar-brand {
    display: inline-block;
  }
}

.header-links {
  text-align: right;
}

.additional-nav {
  list-style: none;
  padding: 0;
  margin: 15px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > li {
    display: block;

    & i {
      font-size: 20px;
    }
  }

  & li:last-of-type {
    margin-right: 0;
  }
}

// select currency
.additional-nav_currency-select {
  position: relative;
  top: -1px;

  & .currency-select__value {
    background: none;
    height: auto;
    width: 50px;
    padding: 5px 12px;
    color: #999 !important;

    &:hover, &:focus {
      background: none;
    }
  }

  &.open .currency-select__value {
    background: none !important;
  }

  &.currency-select .dropdown-menu {
    padding: 0;
    min-width: 50px;

    & > li > a {
      padding: 8px 12px;
    }

    & > li > a:hover, > li > a:focus {
      background-color: #f0f0f0;
    }

    & a:hover, & a:focus {
      background-color: #f0f0f0;
    }
  }

  & .currency-text-valute {
    display: none;
  }

  & .currency-text {
    display: none;
  }
}

.additional-nav_language-select {
  position: relative;

  & i {
    display: none !important;
  }

  & .multi-lang__select {
    padding-right: 0;
  }

  & .item-lang {
    display: block;
  }

  & .language-icon {
    width: 26px;
    height: 26px;
    margin-right: 0 !important;
  }

  & .w-select .dropdown-menu > li > a {
    padding: 8px 7px;
  }

  & .w-select .dropdown-menu {
    border: none;
    border-radius: 0;
    max-width: 40px;
    min-width: auto;
    margin: 3px 0 0 0 !important;
    left: 4px;
  }

  & .change-lang__label {
    display: none;
  }

  & .language-icon_ru {
    background-position: -33px 0;
  }

  & .language-icon_de {
    background-position: -67px 0;
  }

  & .language-icon_fr {
    background-position: -101px 0;
  }

  & .language-icon_it {
    background-position: -135px 0;
  }

  & .language-icon_es {
    background-position: -169px 0;
  }

  & .multi-lang__language-text {
    display: none;
  }
}

.header__navbar {
  z-index: 2;
  position: relative;

  & a {
    color: #001d4b;
  }

  & a:hover, & a:focus {
    background: none !important;
    color: #56aec6;
  }
}

.navbar-header {
  float: none;
  position: relative;
}

@media (max-width: 767px) {
  .navbar-header .navbar-brand {
    display: inline-block;
  }

  .header-content__brand {
    display: none;
  }

  .header, .header nav {
    height: 53px;
    min-width: 320px;
  }

  .additional-nav {
    justify-content: center;
  }

  .navbar-collapse {
    display: none;
    margin-left: -16px !important;
    overflow-y: auto;
    background: #fff;

    &.in {
      padding: 0;
      overflow: visible;
      width: 100vw;
      max-height: 100%;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
      box-shadow: 0 6px 7px 0 rgba(0, 0, 0, 0.1);
    }

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }

    & li a {
      text-align: left;
      border-bottom: none;
    }
  }

  .navbar-toggle {
    padding: 7px 0;
    float: right !important;
    margin-right: 25px;
    position: relative;
    top: 0;
    z-index: 1000;

    & .icon-bar {
      display: block;
      width: 24px;
      height: 3px;
    }

    & .icon-bar + .icon-bar {
      margin-top: 6px;
    }
  }

  .header__navbar {
    display: block;
    margin: 0;
    width: 100%;
    padding: 0 0 20px 0;

    & > li > a {
      font-size: 16px;
      position: relative;
    }

    & > li.active a, & > li a:hover, & > li a:focus {
      background: transparent !important;
    }

    & > li.header-navbar__hide-mobile {
      display: none;
    }

    & .dropdown-toggle {
      display: none;
    }

    & .dropdown-menu {
      display: block;
      position: relative;
      background: none;
      border: none;
      box-shadow: none;
      width: 100%;

      & li {
        position: relative;

        & a {
          color: #fff;
        }
      }
    }
  }
}