.btn {
  border-radius: 2px;
  outline: none !important;
}

.btn-success {
  background: #37ab1e;
  border-color: #37ab1e;

  &:hover {
    background: #28771a;
    border-color: #28771a;
  }
}

.btn-rounded {
  border-radius: 25px;
  padding: 8px 15px;
}

.btn-info {
  background: #23d5d7;
  border-color: #23d5d7;

  &:hover, &:focus {
    background: #2acfdb;
    border-color: #2acfdb;
  }
}

.btn-grey {
  background: #708296;
  color: #fff;

  &:hover, &:focus {
    background: #8599ac;
    color: #fff;
  }
}

.btn-inverse {
  background: #708296;
  color: #121c26;

  &:hover, &:focus {
    background: #adc2d6;
  }
}

.btn-dark {
  background: #223944;
  color: #fff;

  &:hover, &:focus {
    background: #0c1018;
    color: #fff;
  }
}

.btn-link_red {
  color: #ff7a7a;

  &:hover {
    color: #ff554b;
  }
}