.table {
  border: none;
  //table-layout: fixed;
  //border-collapse: collapse;
  overflow-x:auto;

  & thead {
    background: #eff8f8;
  }

  & thead a {
    color: #000;
  }

  & thead a:hover, & thead a:focus {
    text-decoration: none;
  }

  & thead > tr > th {
    border-bottom: 15px solid #fff;
    border-left: none;
    border-right: 1px solid #fff;
  }

  & tbody > tr > td {
    border: 1px solid #e0efef;
    border-left: none;
    border-right: none;
    line-height: 42px;
    white-space: nowrap;
  }

  & tbody > tr > td:first-of-type {
    border-left: 1px solid #e0efef;
  }

  & tbody > tr > td:last-of-type {
    border-right: 1px solid #e0efef;
  }

  & tbody > tr:first-of-type {
    & td {
      position: relative;
    }

    & td::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: -1px;
      width: 100%;
      background: #e0efef;
      height: 1px;
    }
  }

  & th {
    & a.asc:before {
      content: '\E804';
      font-family: "icon";
      font-style: normal;
      font-weight: normal;
      display: inline-block;
      float: right;
    }

    & a.desc:before {
      content: '\E807';
      font-family: "icon";
      font-style: normal;
      font-weight: normal;
      display: inline-block;
      float: right;
    }
  }

  .row-sorting {
    background: #eff8f8;
    font-weight: bold;
  }
}

.table-hover > tbody > tr:hover {
  background-color: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

  &:first-of-type {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  }

  &:last-of-type {
    box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.05);
  }
}

.action-column {
  text-align: center;
}

.action-cell {
  text-align: center;
  position: relative;
  color: #708296;
}

.action-cell .dropdown-menu {
  right: 0;
  left: auto;
}