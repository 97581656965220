.breadcrumb {
  background: none;
  padding: 0;
  margin-bottom: 10px;

  & li + li:before {
    display: none;
  }

  & a {
    font-size: 13px;
    color: #7a8fb1;
    padding: 0 12px 0 0;
    margin: 0 6px 0 0;
    text-decoration: none !important;

    &:hover, &:focus {
      color: #7a8fb1;
    }

    & i {
      position: relative;
      top: -1px;
      left: -3px;
    }

    &:after {
      content: ">";
      position: relative;
      right: -10px;
      font-size: 20px;
      top: 2px;
    }
  }

  & .active {
    color: #7a8fb1;
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .breadcrumb {
    margin: 0;
  }
}