.random-boat {
  background: #ececec;
  padding: 25px;
  margin: 50px 0 30px 0;
}

//.random-boat__header {
//  flex: 0 0 300px;
//}

.random-boat__list {
  width: 100%;
}

.random-boat__title {
  color: #2e2e2e;
  font-weight: 800;
  font-size: 24px;
  margin: 0 0 15px 0;

  & a {
    color: #2e2e2e;
    text-transform: uppercase;

    &:hover, &:focus {
      color: #1aa7ea;
      text-decoration: none;
    }
  }

  & i {
    font-size: 30px;
    line-height: 0;
    position: relative;
    top: 2px;
  }
}

.random-boat__item {
  padding: 0;
  display: block;
  min-height: 240px;
  text-decoration: none !important;
  position: relative;

  & img {
    min-height: 240px;
    object-fit: cover;
  }
}

.random-boat__item-name {
  color: #fff;
  background: rgba(0, 29, 75, 0.8);
  font-weight: bold;
  position: absolute;
  bottom: 40px;
  left: 0;
  padding: 8px 10px;
}

.random-boat__item-price {
  color: #fff;
  background: rgba(22, 216, 228, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px 10px;
}

.random-boat__price-value {
  white-space: nowrap;
  font-weight: bold;
}

.random-boat__item {
  &:hover, &:focus {
    background: #17a7ea;
    color: #fff;
    transition: all 0.3s;

    & .random-boat__item-name {
      color: #fff;

      & i {
        opacity: 1;
      }
    }

    & .random-boat__item-brand {
      color: #fff;
    }

    & .random-boat__item-price {
      color: #fff;
    }

    & .random-boat__price-value {
      color: #fff;
    }
  }
}

.random-boat__price-period {
  white-space: nowrap;
}

.random-boat__list {
  position: relative;

  & .owl-dots {
    display: none;
  }

  & .owl-nav {
    position: absolute;
    right: 0;
    top: -90px;
  }
}

@media (max-width: 900px) {
  .random-boat__list .owl-nav {
    top: -45px;
  }
}

@media (max-width: 767px) {
  .random-boat__list {
    width: 100%;
  }

  .random-boat__note {
    margin-bottom: 10px;
  }
}