:root {
  --duration: 1.5s;
  --container-size: 150px;
  --box-size: 20px;
  --box-border-radius: 15%;
}

.loading-container {
  width: var(--container-size);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 auto;
}

.loading-box {
  width: var(--box-size);
  height: var(--box-size);
  position: relative;
  display: block;
  -webkit-transform-origin: -50% center;
  transform-origin: -50% center;
  border-radius: var(--box-border-radius);
}
.loading-box:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: lightblue;
  border-radius: var(--box-border-radius);
  box-shadow: 0px 0px 10px 0px rgba(28, 159, 255, 0.4);
}
.loading-box:nth-child(1) {
  -webkit-animation: slide var(--duration) ease-in-out infinite alternate;
  animation: slide var(--duration) ease-in-out infinite alternate;
}
.loading-box:nth-child(1):after {
  -webkit-animation: color-change var(--duration) ease-in-out infinite alternate;
  animation: color-change var(--duration) ease-in-out infinite alternate;
}
.loading-box:nth-child(2) {
  -webkit-animation: flip-1 var(--duration) ease-in-out infinite alternate;
  animation: flip-1 var(--duration) ease-in-out infinite alternate;
}
.loading-box:nth-child(2):after {
  -webkit-animation: squidge-1 var(--duration) ease-in-out infinite alternate;
  animation: squidge-1 var(--duration) ease-in-out infinite alternate;
}
.loading-box:nth-child(3) {
  -webkit-animation: flip-2 var(--duration) ease-in-out infinite alternate;
  animation: flip-2 var(--duration) ease-in-out infinite alternate;
}
.loading-box:nth-child(3):after {
  -webkit-animation: squidge-2 var(--duration) ease-in-out infinite alternate;
  animation: squidge-2 var(--duration) ease-in-out infinite alternate;
}
.loading-box:nth-child(4) {
  -webkit-animation: flip-3 var(--duration) ease-in-out infinite alternate;
  animation: flip-3 var(--duration) ease-in-out infinite alternate;
}
.loading-box:nth-child(4):after {
  -webkit-animation: squidge-3 var(--duration) ease-in-out infinite alternate;
  animation: squidge-3 var(--duration) ease-in-out infinite alternate;
}
.loading-box:nth-child(5) {
  -webkit-animation: flip-4 var(--duration) ease-in-out infinite alternate;
  animation: flip-4 var(--duration) ease-in-out infinite alternate;
}
.loading-box:nth-child(5):after {
  -webkit-animation: squidge-4 var(--duration) ease-in-out infinite alternate;
  animation: squidge-4 var(--duration) ease-in-out infinite alternate;
}
.loading-box:nth-child(2):after {
  background-color: #1C9FFF;
}
.loading-box:nth-child(3):after {
  background-color: #1FB1FD;
}
.loading-box:nth-child(4):after {
  background-color: #22C7FB;
}
.loading-box:nth-child(5):after {
  background-color: #23D3FB;
}

@-webkit-keyframes slide {
  0% {
    background-color: #1795FF;
    -webkit-transform: translatex(0vw);
    transform: translatex(0vw);
  }
  100% {
    background-color: #23D3FB;
    -webkit-transform: translatex(calc(var(--container-size) - (var(--box-size) * 1.25)));
    transform: translatex(calc(var(--container-size) - (var(--box-size) * 1.25)));
  }
}

@keyframes slide {
  0% {
    background-color: #1795FF;
    -webkit-transform: translatex(0vw);
    transform: translatex(0vw);
  }
  100% {
    background-color: #23D3FB;
    -webkit-transform: translatex(calc(var(--container-size) - (var(--box-size) * 1.25)));
    transform: translatex(calc(var(--container-size) - (var(--box-size) * 1.25)));
  }
}
@-webkit-keyframes color-change {
  0% {
    background-color: #1795FF;
  }
  100% {
    background-color: #23D3FB;
  }
}
@keyframes color-change {
  0% {
    background-color: #1795FF;
  }
  100% {
    background-color: #23D3FB;
  }
}
@-webkit-keyframes flip-1 {
  0%, 15% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  35%, 100% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}
@keyframes flip-1 {
  0%, 15% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  35%, 100% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}
@-webkit-keyframes squidge-1 {
  5% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  15% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
  25%, 20% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(0.8) scaley(1.4);
    transform: scalex(0.8) scaley(1.4);
  }
  55%, 100% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  40% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
}
@keyframes squidge-1 {
  5% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  15% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
  25%, 20% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(0.8) scaley(1.4);
    transform: scalex(0.8) scaley(1.4);
  }
  55%, 100% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  40% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
}
@-webkit-keyframes flip-2 {
  0%, 30% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50%, 100% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}
@keyframes flip-2 {
  0%, 30% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50%, 100% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}
@-webkit-keyframes squidge-2 {
  20% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  30% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
  40%, 35% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(0.8) scaley(1.4);
    transform: scalex(0.8) scaley(1.4);
  }
  70%, 100% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  55% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
}
@keyframes squidge-2 {
  20% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  30% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
  40%, 35% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(0.8) scaley(1.4);
    transform: scalex(0.8) scaley(1.4);
  }
  70%, 100% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  55% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
}
@-webkit-keyframes flip-3 {
  0%, 45% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  65%, 100% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}
@keyframes flip-3 {
  0%, 45% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  65%, 100% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}
@-webkit-keyframes squidge-3 {
  35% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  45% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
  55%, 50% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(0.8) scaley(1.4);
    transform: scalex(0.8) scaley(1.4);
  }
  85%, 100% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  70% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
}
@keyframes squidge-3 {
  35% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  45% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
  55%, 50% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(0.8) scaley(1.4);
    transform: scalex(0.8) scaley(1.4);
  }
  85%, 100% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  70% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
}
@-webkit-keyframes flip-4 {
  0%, 60% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  80%, 100% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}
@keyframes flip-4 {
  0%, 60% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  80%, 100% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}
@-webkit-keyframes squidge-4 {
  50% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  60% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
  70%, 65% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(0.8) scaley(1.4);
    transform: scalex(0.8) scaley(1.4);
  }
  100%, 100% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  85% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
}
@keyframes squidge-4 {
  50% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  60% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
  70%, 65% {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: scalex(0.8) scaley(1.4);
    transform: scalex(0.8) scaley(1.4);
  }
  100%, 100% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1) scaley(1);
    transform: scalex(1) scaley(1);
  }
  85% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scalex(1.3) scaley(0.7);
    transform: scalex(1.3) scaley(0.7);
  }
}
