.map-header {
  height: 80px;
  background-image: linear-gradient(to top, #169ce4 0%, rgba(0, 29, 75, 0.75) 100%);
}

.map-search {
  position: relative;
}

.map-search {
  height: 100vh;

  & > .loading-container {
    display: none;
  }

  & #map {
    height: 100vh;
  }
}

.map-search_loading {
  display: flex;
  align-items: center;

  & > .loading-container {
    display: flex;
    width: 100%;
    flex: 1 0 100%;
    text-align: center;
    justify-content: center;
  }
}

// фильтр
.map-filter {
  background: #f5f5f5;
  display: flex;

  & #search-extend {
    display: none;
  }

  .control-label {
    display: none;
  }

  .boat-search {
    padding: 10px 0;
    display: flex;
  }

  .form-control {
    padding: 0 5px 0 5px;
  }

  .form-group {
    margin: 0 10px 0 0;
  }

  .extend-btn__action {
    display: none;
  }

  .form-group__btn {
    display: none;
  }

  .boat-search__date {
    margin: 0;

    & .form-group {
      margin: 0;
    }
  }

  .boat-search__category {
    flex-wrap: nowrap;

    & .checkbox-item {
      width: 25%;
      white-space: nowrap;
      padding: 0 5px 0 0;
      min-width: 140px;
    }
  }

  .slider {
    display: none;
  }

  .field-with_captain {
    white-space: nowrap;

    & .checkbox-item {
      width: 185px;
    }
  }

  .boat-search__slider-control {
    width: 250px;

    & .form-group {
      margin: 0;
    }
  }

  .boat-search__date {
    width: 260px;
  }

  .field-brand, .field-region {
    margin: 0;
  }

  .field-boatchartersearch-region_name {
    width: 100%;
    max-width: 280px;
    order: 1;
  }

  .form-group__date {
    order: 2;
  }

  .field-boatchartersearch-brand_name {
    width: 100%;
    max-width: 280px;
    order: 3;
  }

  .boat-search__category-group {
    order: 4;
  }

  .boat-search__with_captain-group {
    order: 5;
  }

  .form-group__price {
    order: 6;
  }
}

.map-filter__submit {
  background: #1a3a48;
  width: 60px;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    background: #17a7ea;
  }

  & i {
    font-size: 40px;

    &:before {
      margin: 0;
    }
  }
}

.map-filter__total {
  color: #17a7ea;
  font-size: 20px;
  white-space: nowrap;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}

// Данные поиска
.map-search__boats {
  width: 340px;
  background: #fff;
  position: absolute;
  height: 100%;
  z-index: 1;
  right: 0;
  top: 0;
  overflow-y: auto;
  padding: 10px;
}

.map-search__boats-list {
  min-height: 100%;
  position: relative;

  & .loading-container {
    position: absolute;
    top: 50%;
    left: 100px;
    z-index: -1;
    opacity: 0;
  }
}

.map-search__boats-list_loading {
  & .loading-container {
    z-index: 1;
    opacity: 1;
  }

  animation: waitAppendBoat 2s infinite;
}

@-webkit-keyframes waitAppendBoat {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes waitAppendBoat {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes waitAppendBoat {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes waitAppendBoat {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

// boat
.map-boat-row {
  border: 1px solid #ececec;
  font-size: 14px;
}

.map-boat-row__header {
  background: #f5f5f5;
  display: flex;
  text-decoration: none !important;
}

.map-boat-row__photo {
  width: 100%;
}

.map-boat-row__price {
  width: 100%;
  text-align: right;
  padding: 5px;
}

.map-boat-row__price-from {
  color: #909090;
}

.map-boat-row__price-period {
  color: #909090;
}

.map-boat-row__price-value {
  font-weight: 700;
}

.map-boat-row__body {
  padding: 10px;
}

.map-boat-row__info-title {
  color: #2e2e2e;
  font-weight: 700;
  padding: 0 0 5px 0;
}

.map-boat-row__info-title_brand {
  color: #909090;
}

.map-boat-row__info-place {
  padding: 0 0 5px 0;
}

.map-boat-row__info-specification {
  color: #26aaeb;
  font-size: 12px;
  padding: 0;

  & li {
    display: inline-block;
  }

  & li:before {
    content: "|";
    padding-right: 5px;
  }

  & li:last-of-type:after {
    content: "|";
    padding-left: 5px;
  }
}

.map-boat-row__info-opportunity {
  color: #909090;
  font-size: 12px;
  padding: 0;

  & li {
    display: inline-block;
    padding-right: 5px;

    & i {
      display: none;
    }

    & span {
      font-weight: bold;
    }
  }
}

.gmap-control-overlay {
  width: 100%;
  height: 100%;
  pointer-events: none;
  padding: 10px 10px 10px 10px;
}

.gmap-control {
  color: rgb(25, 25, 25);
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
  border-radius: 3px;
  font-family: Montserrat, Arial, sans-serif;
}

.gmap-control-marker-popover-hidden {
  opacity: 0;
}

.gmap-control-marker-popover {
  position: absolute;
  z-index: 10000005;
  bottom: 0;
  top: 0;
  margin: 10px;
  pointer-events: none;
  width: 40%;
  overflow-wrap: break-word;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  border-radius: 3px;
  height: 240px
}

.gmap-control-marker-popover > * {
  pointer-events: none
}

.gmap-control-marker-popover img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 140px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  animation: zoomPhoto 5s linear;
  transform: scale(1.1)
}

.gmap-control-marker-popover-img-box {
  position: relative;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 140px;
  background-color: #eee;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden
}

@keyframes zoomPhoto {
  from {
    transform: scale(1)
  }

  to {
    transform: scale(1.1)
  }
}

@media (max-width: 1800px) {
  .map-filter {
    .boat-search {
      flex-wrap: wrap;
      padding: 10px 0 0 0;

      & > .form-group {
        margin-bottom: 10px;
      }
    }

    .boat-search__category-group {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 767px) {
  .map-filter {
    position: relative;

    .control-label {
      display: block;
    }

    .boat-search {
      display: block;
      position: absolute;
      left: 0;
      top: 60px;
      z-index: -1;
      height: 0;
      width: 100%;
      background: #fff;
      transition: all 0.3s;
      overflow-y: auto;
      padding: 10px;
    }

    .boat-search__category {
      flex-wrap: wrap;

      & .checkbox-item {
        width: 50%;
        white-space: nowrap;
        padding: 0 5px 10px 0;
        min-width: auto;
      }
    }

    .filter_open {
      z-index: 1;
      height: calc(100vh - 113px);
    }
  }

  .map-search {
    flex-wrap: wrap;
  }

  .map-filter__total {
    display: none;
  }

  .map-search__boats {
    position: static;
    width: 100%;
  }

  .loading-container {
    position: absolute;
    left: 0;
    top: -60px;
    z-index: 100;
    background: rgba(255, 255, 255, 0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}