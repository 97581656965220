.nav-tabs {
  border: none;

  & > li > a {
    font-weight: bold;
    color: #909090;
    font-size: 18px;
    border:none;
    line-height: 22px
  }

  & > li.active > a, & > li.active > a:hover, & > li.active > a:focus, & > li > a:hover, & > li > a:focus {
    color: #121c26;
    border: none;
    background: none;
  }

  //& > li:first-of-type > a {
  //  padding-left: 0;
  //}

  & > li.active > a, & > li.active > a:hover, & > li.active > a:focus {
    line-height: 20px;
    border-bottom: 4px solid #17a7ea;
  }
}